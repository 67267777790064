/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {Modal, Button, Input, RangePicker} from '../../../../components';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';
interface ModalFilterProps {
  visible: boolean;
  onClose?: () => void;
  handleFilter?: () => void;
  handleClean?: () => void;
  onChangeRangePicker?: (any) => void;
  valueFilter?: any;
  setValueFilter?: (any) => void;
}

function ModalFilter({
  visible,
  onClose,
  handleFilter,
  handleClean,
  onChangeRangePicker,
  valueFilter,
  setValueFilter,
}: ModalFilterProps) {
  const onChange = (event: any, key: string) => {
    setValueFilter((prevState: any) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  };

  const handleCleanForm = () => {
    handleClean();
  };

  const handleClose = () => {
    onClose();
    if (!visible) {
      handleCleanForm();
    }
  };

  return (
    <Modal visible={visible} onClose={handleClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY EMAIL
            </Typography>
            <Input
              type="email"
              variant="outlined"
              placeholder="Enter email"
              size="small"
              value={valueFilter?.email_contains}
              onChange={e => onChange(e, 'email_contains')}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY ACCOUNT ID
            </Typography>
            <Input
              type="text"
              variant="outlined"
              placeholder="Enter Account ID"
              size="small"
              value={valueFilter?.membershipId_contains}
              onChange={e => onChange(e, 'membershipId_contains')}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY LOYALTY MEMBERSHIP ID
            </Typography>
            <Input
              type="text"
              variant="outlined"
              placeholder="Enter LoyaltyMembershipID"
              size="small"
              value={valueFilter?.loyaltyMembershipId_eq}
              onChange={e => onChange(e, 'loyaltyMembershipId_eq')}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY OBJECT ID
            </Typography>
            <Input
              type="text"
              variant="outlined"
              placeholder="Enter ObjectID"
              size="small"
              value={valueFilter?.objectId_eq}
              onChange={e => onChange(e, 'objectId_eq')}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY SNAPP’S ACCOUNT ID
            </Typography>
            <Input
              type="text"
              variant="outlined"
              placeholder="Enter SnApp’s AccountID"
              size="small"
              value={valueFilter?.accountId_eq}
              onChange={e => onChange(e, 'accountId_eq')}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY TERMINATED DATE
            </Typography>
            <RangePicker
              value={valueFilter.terminatedDate}
              onChange={onChangeRangePicker}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button
              disabled={
                !valueFilter?.email_contains &&
                !valueFilter?.membershipId_contains &&
                (!valueFilter?.terminatedDate[0] ||
                  !valueFilter?.terminatedDate[1]) &&
                !valueFilter?.loyaltyMembershipId_eq &&
                !valueFilter?.objectId_eq &&
                !valueFilter?.accountId_eq
              }
              variant="text"
              onClick={handleCleanForm}
            >
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button
              disabled={
                !valueFilter?.email_contains &&
                !valueFilter?.membershipId_contains &&
                (!valueFilter?.terminatedDate[0] ||
                  !valueFilter?.terminatedDate[1]) &&
                !valueFilter?.loyaltyMembershipId_eq &&
                !valueFilter?.objectId_eq &&
                !valueFilter?.accountId_eq
              }
              onClick={handleFilter}
            >
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
