/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState, useMemo} from 'react';
import {Helmet} from 'react-helmet-async';
import {Delete, Edit, CheckSharp as Active} from '@mui/icons-material';
import {Grid, TableCell, TableBody, IconButton, Stack} from '@mui/material';
import {ColumnsType, typeRoutes} from '../../types/typeAllTransaction';
import {
  Breadcrumbs,
  ModalConfirm,
  Table,
  Tooltips,
  Chip,
  Button,
} from '../../components';

import {
  SortableContainer,
  SortableHandle,
  SortableElement,
} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import queryString from 'query-string';

import EnhancedTableToolbar from './EnhancedTableToolbar/EnhancedTableToolbar';
import {images} from '../../constants/image';
import useAuth from '../../hooks/useAuth';
import MemberDashboardAsset from '../../services/MemberDashboard/member-dashboard.service';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
  StyledAction,
  StyledIcon,
} from './MemberDashboard.style';
import {ColorType} from '../../types/typeChip';
import success from '../../constants/success';
import ModalUpdate from './ModalUpdate/ModalUpdate';
import {useSearchParams} from 'react-router-dom';

const DragHandle = SortableHandle(({style, children, width, ...other}: any) => (
  <TableCell {...other} width={width}>
    {' '}
    {children}{' '}
  </TableCell>
));

const TableBodySortable = SortableContainer(({children}: any) => (
  <TableBody>{children}</TableBody>
));

const Row = SortableElement(
  ({row, renderstatus, renderimage, ...other}: any) => {
    return (
      <StyledTableRow {...other}>
        <DragHandle align="left" width="30%">
          {row?.displayOrder || '-'}
        </DragHandle>
        <DragHandle component="th" scope="row" width="40%">
          {renderimage(row)}
        </DragHandle>
        <DragHandle align="left" width="30%">
          {renderstatus(row?.status)}
        </DragHandle>
        <TableCell
          align="right"
          padding="checkbox"
          style={{paddingRight: '10px'}}
          width={250}
        >
          {other.onClick()}
        </TableCell>
      </StyledTableRow>
    );
  }
);

const MemberDashboard = (props: any) => {
  const formatDateTime = 'DD/MM/YYYY HH:mm';
  const [searchParams, setSearchParams] = useSearchParams();

  // states
  const {showNotification} = useAuth();
  const [isChanged, setIsChanged] = useState(false);
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [focusId, setFocusId] = useState(null);
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [isModalConfirmDelete, setIsModalConfirmDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadButtonSave, setLoadButtonSave] = useState(false);
  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    _sort: 'isPublished:desc;displayOrder:asc;createdDateTime:desc',
    status_eq: undefined,
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'displayOrder',
      numeric: false,
      disablePadding: false,
      label: 'Order',
    },
    {
      dataIndex: 'mastheadImage',
      numeric: false,
      disablePadding: false,
      label: 'Masthead Item',
    },
    {
      dataIndex: 'isPublished',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const getMemberDashboard = async () => {
    setIsLoading(true);
    await new MemberDashboardAsset()
      .GetAllMemberDashboardAsset(params)
      .then(res => {
        if (res.success) {
          setDataSource(res.data.items);
          setTotalPage(res.data.total);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };

  const toggleModalConfirmDelete = () => {
    setIsModalConfirmDelete(!isModalConfirmDelete);
  };

  const onDelete = () => {
    setIsLoading(true);
    new MemberDashboardAsset()
      .update(selectedId, {isPublished: false})
      .then(res => {
        if (res && res?.success) {
          showNotification({
            message: 'Masthead deactivated successfully!',
          });
          toggleModalConfirmDelete();
          getMemberDashboard();
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const onActive = id => {
    setIsLoading(true);
    new MemberDashboardAsset().update(id, {isPublished: true}).then(res => {
      if (res?.success) {
        showNotification({
          message: 'Masthead activated successfully!',
        });
        getMemberDashboard();
        setIsLoading(false);
      } else {
        showNotification({
          message: res.errorMessage,
          variation: 'error',
        });
        setIsLoading(false);
      }
    });
  };

  const onSave = () => {
    setIsLoading(true);
    setLoadButtonSave(true);
    const dataSourceLive = dataSource.filter(item => item.isPublished === true);
    new MemberDashboardAsset()
      .ArrangeMemberDashboardAssets({
        memberDashboardAssets: dataSourceLive.map((item, index) => ({
          id: item.id,
          displayOrder: index + 1,
        })),
      })
      .then(res => {
        if (res?.success) {
          showNotification({
            message: 'Masthead successfully edited!',
          });
          getMemberDashboard();
          setIsLoading(false);
          setIsChanged(false);
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
          setIsLoading(false);
        }
        setLoadButtonSave(false);
      });
  };

  const renderImage = data => {
    return (
      <Grid sx={{display: 'flex', alignItems: 'center'}}>
        <img src={data?.mastheadImage} width={60} height={80} />{' '}
        <Typography px={4}>{data?.title}</Typography>
      </Grid>
    );
  };

  const renderStatus = value => {
    if (value === true) {
      return <Chip label="Published" color={'success' as ColorType} />;
    } else {
      return <Chip label="Unpublished" color={'default' as ColorType} />;
    }
  };

  const renderAction = (value: string) => {
    if (focusId === value?.id) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                setSelectedId(value?.id);
                toggleModalUpdate();
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
          {value.isPublished === true ? (
            <Tooltips title="Deactivate">
              <IconButton
                aria-label="details"
                onClick={() => {
                  setSelectedId(value?.id);
                  toggleModalConfirmDelete();
                }}
                size="medium"
              >
                <Delete style={StyledIcon} color="error" />
              </IconButton>
            </Tooltips>
          ) : (
            <Tooltips title="Activate">
              <IconButton
                aria-label="details"
                onClick={() => {
                  onActive(value?.id);
                }}
                size="medium"
              >
                <Active style={StyledIcon} color="success" />
              </IconButton>
            </Tooltips>
          )}
        </StyledAction>
      );
    }
  };

  const onSortEnd = ({oldIndex, newIndex}) => {
    if (
      dataSource[oldIndex]?.isPublished !== true ||
      dataSource[newIndex]?.isPublished !== true
    ) {
      return;
    }
    dataSource[oldIndex].displayOrder = newIndex + 1;
    if (oldIndex > newIndex) {
      for (let i = newIndex; i < oldIndex; i++) {
        dataSource[i].displayOrder = i + 2;
      }
    } else {
      for (let i = oldIndex + 1; i <= newIndex; i++) {
        dataSource[i].displayOrder = i;
      }
    }

    setIsChanged(true);

    setDataSource(arrayMoveImmutable(dataSource, oldIndex, newIndex));
  };

  const toggleModalUpdate = () => {
    setIsModalUpdate(!isModalUpdate);
  };

  const renderModalUpdate = useMemo(() => {
    if (selectedId !== null && isModalUpdate) {
      return (
        <ModalUpdate
          id={selectedId}
          visible={isModalUpdate}
          onClose={toggleModalUpdate}
          reloadPage={getMemberDashboard}
        />
      );
    }
  }, [selectedId, isModalUpdate, getMemberDashboard, toggleModalUpdate]);

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
      })
    );

    getMemberDashboard();
  }, [params]);

  return (
    <React.Fragment>
      <Helmet title="Member Dashboard" />
      {renderModalUpdate}
      <ModalConfirm
        visible={isModalConfirmDelete}
        iconƯidth="80px"
        iconHeight="100px"
        icon={images.image_deactivate}
        footer={
          <Grid container>
            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                justifyContent="center"
                gap={12}
              >
                <Button
                  variant="contained"
                  onClick={onDelete}
                  width="125px"
                  loading={isLoading}
                >
                  Deactivate
                </Button>
                <Button
                  variant="outlined"
                  onClick={toggleModalConfirmDelete}
                  width="125px"
                  loading={isLoading}
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
        }
        title={
          <Typography variant="h4">
            <b>Are you sure you want to deactivate this masthead?</b>
          </Typography>
        }
        subTitle={
          <Typography>
            Please note that this change will take effect instantly on App.{' '}
            <br /> <br /> You can always reactivate it, provided there is an
            available slot within the limit of 6 mastheads.
          </Typography>
        }
        onClose={toggleModalConfirmDelete}
        onConfirm={onDelete}
        isLoading={isLoading}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Member Dashboard
          </Typography>
          <Breadcrumbs
            routes={[
              typeRoutes('Decide which Masthead item to publish', null, true),
            ]}
          />
        </Grid>
        <Grid item>
          <Button
            disabled={!isChanged}
            onClick={onSave}
            margin={'0 10px 0 0'}
            loading={isLoading || loadButtonSave}
          >
            Save Changes
          </Button>
        </Grid>
      </Grid>
      <Divider my={6} />

      <EnhancedTableToolbar
        isHeader={true}
        isLoading={isLoading}
        isAddNew
        setParams={setParams}
      />
      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        textNodata="There are no assets in this folder."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBodySortable onSortEnd={onSortEnd} useDragHandle lockAxis="y">
            {dataSource?.map((row, index) => {
              return (
                <Row
                  disabled={!row?.isPublished}
                  row={row}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  index={index}
                  rowindex={index}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                  onClick={() => renderAction(row)}
                  renderstatus={() => renderStatus(row?.isPublished)}
                  renderimage={() => renderImage(row)}
                  formatdatetime={formatDateTime}
                />
              );
            })}
          </TableBodySortable>
        )}
      </Table>
    </React.Fragment>
  );
};

export default MemberDashboard;
