/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {CardContent, FormControl, Grid} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import {images} from '../../../constants/image';
import useAuth from '../../../hooks/useAuth';
import success from '../../../constants/success';

import {
  Input,
  TextArea,
  RadioGroups,
  Select,
  Button,
  Modal,
} from '../../../components';

// style
import {
  Card,
  Typography,
  StyledModalTitle,
  StyledIconClose,
  Divider,
} from './ModalUpdate.style';
import MemberDashBoardService from '../../../services/MemberDashboard/member-dashboard.service';
import MobileAssetsService from '../../../services/MobileAssets/mobile-assets.service';
import SimpleSelectFilter from '../../../components/Selects/SimpleSelectFilter/SimpleSelectFilter';

const radioOptions = [
  {
    key: 'Dark',
    value: 1,
  },
  {
    key: 'Light',
    value: 2,
  },
];

const radioOptionsCTALink = [
  {
    label: 'No destination',
    url: 'https://qa.sentosa.com.sg/1',
    value: 1,
  },
  {
    label: 'Map page',
    url: 'https://qa.sentosa.com.sg/2',
    value: 2,
  },
  {
    label: 'Shop Listing page',
    url: 'https://qa.sentosa.com.sg/3',
    value: 3,
  },
  {
    label: 'Member page',
    url: 'https://qa.sentosa.com.sg/4',
    value: 4,
  },
  {
    label: 'Islander Vouchers Listing page',
    url: 'https://qa.sentosa.com.sg/5',
    value: 5,
  },
  {
    label: 'Tips and guides Listing page',
    url: 'https://qa.sentosa.com.sg/6',
    value: 6,
  },
  {
    label: '500K Campaign page',
    url: 'https://qa.sentosa.com.sg/7',
    value: 7,
  },
  {
    label: 'MusicFest Campaign page',
    url: 'https://qa.sentosa.com.sg/8',
    value: 8,
  },
];

interface DataDetail {
  title?: string;
  description?: string;
  gradientColor?: number;
  mobileAssetId?: string;
  cTALink?: string;
  displayOrder?: number | undefined;
  status?: number | undefined;
}

interface ModalUpdate {
  id: number;
  visible: boolean;
  onClose?: () => void;
  reloadPage?: () => void;
}

function ModalUpdate({id, visible, onClose, reloadPage}: ModalUpdate) {
  const {showNotification} = useAuth();
  const [loading, setLoading] = useState(false);
  const [mastheadAssets, setMastheadAsset] = useState([]);

  const [initialValues, setInitialValues] = useState<DataDetail>({
    title: '',
    description: '',
    gradientColor: 1,
    mobileAssetId: '',
    cTALink: '',
    status: 1,
  });

  const onSubmit = values => {
    if (isErrorForm()) return;
    setLoading(true);
    new MemberDashBoardService()
      .UpdateMemberDashboardAsset(id, {
        ...values,
        gradientColor: Number(initialValues?.gradientColor),
        cTALink: initialValues?.cTALink,
        mobileAssetId: initialValues?.mobileAssetId,
      })
      .then(res => {
        if (res !== null && res.success) {
          showNotification({
            message: 'Masthead successfully edited!',
          });
          reloadPage();
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
          reloadPage();
        }
      });
    onClose();
    setLoading(false);
  };

  const isErrorForm = () => {
    if (!initialValues?.mobileAssetId) {
      return true;
    }
  };

  const GetMastheadAssets = () => {
    new MobileAssetsService().GetAllMobileAsset({}).then(res => {
      if (res.code === 200 && res?.data?.items?.length > 0) {
        const mastheadOptions = res?.data?.items?.map((masthead, index) => {
          return {
            label: masthead?.fileName,
            value: masthead?.id,
            image: masthead?.uRL,
            width: 60,
            height: 80,
          };
        });
        setMastheadAsset(mastheadOptions);
      }
    });
  };

  const onChangeSelectMasthead = (_, value) => {
    const newInitialValues = initialValues;
    newInitialValues.mobileAssetId = value.value;
    setInitialValues(newInitialValues);
  };

  const GetDashboardAssetDetail = () => {
    setLoading(true);
    new MemberDashBoardService().GetOneMemberDashboardAsset(id).then(res => {
      if (res?.status === 200) {
        setInitialValues({
          title: res?.data?.data?.title,
          description: res?.data?.data?.description,
          gradientColor: res?.data?.data?.gradientColor,
          mobileAssetId: res?.data?.data?.mobileAssetId,
          cTALink: res?.data?.data?.cTALink,
          status: res?.data?.data?.status,
        });
        setLoading(false);
      }
    });
    setLoading(false);
  };

  const resetForm = () => {
    setInitialValues({
      title: '',
      description: '',
      gradientColor: 1,
      mobileAssetId: '',
      cTALink: '',
      status: undefined,
    });
  };

  useEffect(() => {
    GetDashboardAssetDetail();
    GetMastheadAssets();
  }, []);

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>Update Masthead</StyledModalTitle>
        <StyledIconClose
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />

        <DialogContent>
          <Formik
            initialValues={{
              ...initialValues,
              cTALink:
                radioOptionsCTALink.find(
                  item => item?.url === initialValues?.cTALink
                )?.value || null,
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Fill in the title'),
              description: Yup.string().required('Fill the description'),
              gradientColor: Yup.number().integer(),
              cTALink: Yup.number().required('Select 1 App page'),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldTouched,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Card>
                  <CardContent sx={{padding: 0}}>
                    <Grid container rowSpacing={0} columnSpacing={10} mb="50px">
                      <Grid item xs={12}>
                        <FormControl fullWidth margin="normal">
                          <Typography variant="subtitle2" mb={2}>
                            Title <span className="mandatory-label">(*)</span>
                          </Typography>
                          <Input
                            placeholder="Enter Title"
                            size="small"
                            name="title"
                            value={values.title}
                            variant="outlined"
                            helperText={touched.title && errors.title}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.title && errors.title)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth margin="normal">
                          <Typography variant="subtitle2" mb={2}>
                            Description{' '}
                            <span className="mandatory-label">(*)</span>
                          </Typography>
                          <TextArea
                            label=""
                            placeholder="Enter Description"
                            maxRows={2}
                            name="description"
                            value={values.description}
                            helperText={
                              touched.description && errors.description
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(
                              touched.description && errors.description
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl variant="standard" fullWidth>
                          <Typography variant="subtitle2" mb={2} mt={3}>
                            Background Gradient Colour
                          </Typography>
                          <RadioGroups
                            name="gradientColor"
                            options={radioOptions}
                            values={initialValues.gradientColor}
                            onChange={e => {
                              handleChange(e);
                              const newInitialValues = initialValues;
                              newInitialValues.gradientColor = e.target.value;
                              setInitialValues(newInitialValues);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth sx={{marginBottom: '20px'}}>
                          <Typography variant="subtitle2" mb={2}>
                            Masthead Asset{' '}
                            <span className="mandatory-label">(*)</span>{' '}
                            <Typography
                              variant="body2"
                              sx={{display: 'inline-block'}}
                            >
                              [Select only 1 Asset from the list]
                            </Typography>
                          </Typography>
                          <SimpleSelectFilter
                            textFieldProps={{
                              placeholder: 'Select masthead',
                              size: 'small',
                              name: 'mobileAssetId',
                            }}
                            fullWidth
                            onChange={(_, newValue) => {
                              setFieldTouched('mobileAssetId', true);
                              onChangeSelectMasthead(_, newValue);
                            }}
                            onBlur={handleBlur}
                            options={mastheadAssets}
                            disableClearable
                            helperText={
                              touched.mobileAssetId && 'Select 1 masthead'
                            }
                            error={Boolean(
                              touched.mobileAssetId &&
                                !initialValues.mobileAssetId
                            )}
                            value={
                              mastheadAssets.find(
                                item =>
                                  item?.value === initialValues?.mobileAssetId
                              ) || null
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth sx={{marginBottom: '20px'}}>
                          <Typography variant="subtitle2" mb={2}>
                            CTA Link{' '}
                            <span className="mandatory-label">(*)</span>{' '}
                            <Typography
                              variant="body2"
                              sx={{display: 'inline-block'}}
                            >
                              [Select Redirection to 1 of the App page from
                              Masthead]
                            </Typography>
                          </Typography>
                          <Select
                            labelId="cTALink"
                            name="cTALink"
                            size="small"
                            variant="outlined"
                            displayEmpty
                            placeholder="Select 1 App page"
                            onChangeSelect={e => {
                              handleChange(e);
                              const newInitialValues = initialValues;
                              newInitialValues.cTALink =
                                radioOptionsCTALink.find(
                                  item => item?.value === e.target.value
                                )?.url;
                              setInitialValues(newInitialValues);
                            }}
                            onBlur={handleBlur}
                            helperText={touched.cTALink && errors.cTALink}
                            error={Boolean(touched.cTALink && errors.cTALink)}
                            value={values.cTALink}
                            selectOptions={radioOptionsCTALink}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button type="submit" loading={loading}>
                      Update Masthead
                    </Button>
                  </Grid>
                </Card>
              </form>
            )}
          </Formik>
        </DialogContent>
      </>
    </Modal>
  );
}

export default ModalUpdate;
