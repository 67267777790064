/* eslint-disable node/no-extraneous-import */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {DateRange} from '@mui/lab/DateRangePicker';
import {Grid, TableCell, TableBody, Stack, FormControl} from '@mui/material';
import {useSearchParams} from 'react-router-dom';

import {ColumnsType, typeRoutes} from '../../types/typeIslandersMembers';
import {ColorType} from '../../types/typeChip';
import {
  formatLocalDateFromUTCTime,
  getEndOfDate,
  getStartOfDate,
} from '../../utils';
import AuditLogsService from '../../services/AuditLogs/audit-logs.service';
import {
  Breadcrumbs,
  Table,
  Chip,
  RangePicker,
  EnhancedTableToolbar,
  Button,
  Input,
} from '../../components';
import useAuth from '../../hooks/useAuth';
import success from '../../constants/success';
import ExportExcelService from '../../services/ExportExcel/ExportExcel';
import queryString from 'query-string';

//style
import {
  Typography,
  Divider,
  StyledStack,
  StyledTableRow,
  StylePre,
} from './AuditLogs.style';

function AuditLogs() {
  const formatDateTime = 'HH:mm DD/MM/YYYY';
  const format = 'DD/MM/YYYY';
  const {getUser, showNotification} = useAuth();
  const {email} = getUser() || {};
  const [searchParams, setSearchParams] = useSearchParams();

  // states
  const [order] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy] = React.useState('');
  const [dense] = React.useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  const [, setFocusId] = useState(null);
  const [valueRangePicker, setValueRangePicker] = useState<DateRange<Date>>([
    null,
    null,
  ]);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [valueFilter, setValueFilter] = useState({
    actionName_contains: '',
    eventNameAndAction_contains: '',
    createdDateTime_lte: '',
    createdDateTime_gte: '',
  });
  const searchParamsObject = queryString.parse(searchParams.toString());
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    status_eq: 1,
    actionName_contains: searchParamsObject?.actionName_contains ?? undefined,
    eventNameAndAction_contains:
      searchParamsObject?.eventNameAndAction_contains ?? undefined,
    createdDateTime_lte: searchParamsObject?.createdDateTime_lte ?? undefined,
    createdDateTime_gte: searchParamsObject?.createdDateTime_gte ?? undefined,
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'adminEmail',
      numeric: false,
      disablePadding: false,
      label: 'Admin’s Email',
    },
    {
      dataIndex: 'action',
      numeric: false,
      disablePadding: false,
      label: 'Action',
    },
    {
      dataIndex: 'createdDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Time and Date',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const onChange = event => {
    const {name, value} = event.target;
    setValueFilter({
      ...valueFilter,
      [name]: value,
    });
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const handleDeleteChip = (type: string) => {
    if (type === 'date') {
      deleteSearchParams('createdDateTime_lte');
      deleteSearchParams('createdDateTime_gte');
      setValueRangePicker([null, null]);
      setValueFilter({
        ...valueFilter,
        createdDateTime_lte: '',
        createdDateTime_gte: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        createdDateTime_lte: undefined,
        createdDateTime_gte: undefined,
      }));
      return;
    }
    deleteSearchParams(type);
    setValueFilter({
      ...valueFilter,
      [type]: '',
    });
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: 10,
      [type]: undefined,
    }));
  };

  const getListAuditLogs = () => {
    setIsLoading(true);
    new AuditLogsService()
      .getAll({...params})
      .then(res => {
        if (res?.data && Array.isArray(res.data.items)) {
          setDataSource(res.data.items);
          setTotalPage(res?.data?.total);
          setIsLoading(false);
        }
      })
      .catch(error => {
        setIsLoading(false);
        // Get api error => show notification or no items listing
        setError(error);
      });
  };

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportEmailExcelAuditLogs({
        ...params,
        email: email,
      })
      .then(res => {
        setIsLoadingExport(false);
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const onChangeRangePicker = (event: any) => {
    setValueRangePicker(event);
    setValueFilter({
      ...valueFilter,
      createdDateTime_lte: event[1],
      createdDateTime_gte: event[0],
    });
  };

  const onClickFilter = () => {
    if (valueFilter?.createdDateTime_lte && valueFilter?.createdDateTime_gte) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        createdDateTime_lte: getEndOfDate(valueFilter?.createdDateTime_lte),
        createdDateTime_gte: getStartOfDate(valueFilter?.createdDateTime_gte),
      }));
    }

    if (valueFilter?.actionName_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        actionName_contains: valueFilter?.actionName_contains,
      }));
    }

    if (valueFilter?.eventNameAndAction_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        eventNameAndAction_contains: valueFilter?.eventNameAndAction_contains,
      }));
    }
  };

  const onClickClear = () => {
    setValueFilter({
      actionName_contains: '',
      eventNameAndAction_contains: '',
      createdDateTime_lte: '',
      createdDateTime_gte: '',
    });
    setValueRangePicker([null, null]);
    setParams(() => ({
      _page: 0,
      _size: 10,
      status_eq: 1,
      actionName_contains: undefined,
      eventNameAndAction_contains: undefined,
      createdDateTime_lte: undefined,
      createdDateTime_gte: undefined,
    }));
  };

  const renderActionLogs = value => {
    if (value) {
      return (
        <>
          {value?.action?.length === 1 && value?.action[0].includes('{', '}') && (
            <span
              style={{
                fontWeight: 600,
              }}
            >
              {value?.eventName}:
            </span>
          )}
          <span
            style={{
              fontWeight: 600,
            }}
          >
            {value?.eventName}:
          </span>
          <>
            {value.action.map((item, index) => {
              try {
                return (
                  <>
                    <StylePre className={`obj${index}`} key={index}>
                      {JSON.stringify(JSON.parse(item), null, 2)}
                    </StylePre>
                  </>
                );
              } catch (error) {
                return item;
              }
            })}
          </>
        </>
      );
    }
  };

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
      })
    );
    getListAuditLogs();
  }, [params]);

  return (
    <React.Fragment>
      <Helmet title="Audit Logs" />
      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Audit Logs
          </Typography>
          <Breadcrumbs
            routes={[
              // typeRoutes('Dashboard', `/${path.DASHBOARD}`),
              typeRoutes('Audit logs', null, true),
            ]}
          />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          {/* <ButtonExport
            params={params}
            exportMethod={new AuditLogsService().exportAuditLogs}
            fileName="AUDIT_LOGS"
          /> */}
          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              xs={8}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <StyledStack direction="row" spacing={3}>
                {searchParamsObject?.actionName_contains && (
                  <Chip
                    label={searchParamsObject?.actionName_contains}
                    color={'default' as ColorType}
                    onDelete={() => {
                      handleDeleteChip('actionName_contains');
                    }}
                  />
                )}
                {searchParamsObject?.eventNameAndAction_contains && (
                  <Chip
                    label={searchParamsObject?.eventNameAndAction_contains}
                    color={'default' as ColorType}
                    onDelete={() => {
                      handleDeleteChip('eventNameAndAction_contains');
                    }}
                  />
                )}
                {searchParamsObject?.createdDateTime_lte &&
                  searchParamsObject?.createdDateTime_gte && (
                    <Chip
                      label={`${formatLocalDateFromUTCTime(
                        searchParamsObject?.createdDateTime_gte,
                        format
                      )} - ${formatLocalDateFromUTCTime(
                        searchParamsObject?.createdDateTime_lte,
                        format
                      )}`}
                      color={'default' as ColorType}
                      onDelete={() => {
                        handleDeleteChip('date');
                      }}
                    />
                  )}
              </StyledStack>
            </Grid>
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <EnhancedTableToolbar
        isHeader={true}
        childrenHeader={
          <div
            style={{
              width: '80%',
              display: 'flex',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <FormControl fullWidth>
                <Input
                  type="text"
                  name="actionName_contains"
                  placeholder="Enter Email"
                  size="small"
                  variant="outlined"
                  value={valueFilter.actionName_contains}
                  onChange={e => onChange(e)}
                />
              </FormControl>
              <FormControl fullWidth>
                <Input
                  type="text"
                  name="eventNameAndAction_contains"
                  placeholder="Enter Action Name"
                  size="small"
                  variant="outlined"
                  value={valueFilter.eventNameAndAction_contains}
                  onChange={e => onChange(e)}
                />
              </FormControl>
              <FormControl fullWidth>
                <RangePicker
                  value={valueRangePicker}
                  onChange={onChangeRangePicker}
                />
              </FormControl>
            </div>
            <Stack spacing={3} direction="row" sx={{marginLeft: '20px'}}>
              <Button
                type="button"
                onClick={onClickFilter}
                width="90px"
                loading={isLoading}
              >
                Filter
              </Button>
              <Button
                variant="outlined"
                type="button"
                onClick={onClickClear}
                width="90px"
                loading={isLoading}
              >
                Clear
              </Button>
            </Stack>
          </div>
        }
      />
      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        textNodata="There are no audit log(s) matching the filter."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        isLoading={isLoading}
        count={totalPage}
      >
        {!error && (
          <TableBody>
            {dataSource?.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={index}
                  tabIndex={-1}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell component="th" scope="row">
                    {row.adminEmail}
                  </TableCell>
                  <TableCell sx={{wordBreak: 'break-word'}} align="left">
                    {renderActionLogs(row)}
                  </TableCell>
                  <TableCell align="left">
                    {formatLocalDateFromUTCTime(
                      row.createdDateTime,
                      formatDateTime
                    )}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
}

export default AuditLogs;
