/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Delete, Edit, ContentCopy as Copy} from '@mui/icons-material';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {ColumnsType, RowType, typeRoutes} from '../../types/typeAllTransaction';
import {Breadcrumbs, ModalConfirm, Table, Tooltips} from '../../components';
import {images} from '../../constants/image';
import useAuth from '../../hooks/useAuth';
import MobileAssetsService from '../../services/MobileAssets/mobile-assets.service';
import {formatDate, formatLocalDateFromUTCTime} from '../../utils';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
  StyledAction,
  StyledIcon,
} from './ManageAssets.style';

const ManageAssets = (props: any) => {
  const formatDateTime = 'DD/MM/YYYY HH:mm';

  // states
  const {showNotification} = useAuth();
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [focusId, setFocusId] = useState(null);
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [isModalConfirmDelete, setIsModalConfirmDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    _sort: 'createdDateTime:desc',
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'image',
      numeric: false,
      disablePadding: false,
      label: 'Image Preview',
    },
    {
      dataIndex: 'fileName',
      numeric: false,
      disablePadding: false,
      label: 'File Name',
    },
    {
      dataIndex: 'updatedDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Uploaded Datetime',
    },
    {
      dataIndex: 'owner',
      numeric: false,
      disablePadding: false,
      label: 'Owner',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const getManageAssets = () => {
    setIsLoading(true);
    new MobileAssetsService().GetAllMobileAsset(params).then(res => {
      if (res.success) {
        setDataSource(res.data.items);
        setTotalPage(res.data.total);
      }
      setIsLoading(false);
    });
  };

  const toggleModalConfirmDelete = () => {
    setIsModalConfirmDelete(!isModalConfirmDelete);
  };

  const onCopy = values => {
    navigator.clipboard.writeText(dataSource[values]?.uRL);
    showNotification({
      message: 'Copied to clipboard',
    });
  };

  const onDelete = () => {
    setIsLoading(true);
    new MobileAssetsService().DeleteMobileAsset(selectedId).then(res => {
      if (res && res?.success) {
        showNotification({
          message: 'Deleted successfuly',
        });
        toggleModalConfirmDelete();
        getManageAssets();
      } else {
        showNotification({
          message: res.errorMessage,
          variation: 'error',
        });
      }
    });
  };

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const renderImage = data => {
    const fileType =
      data?.fileName.split('.')[data?.fileName.split('.').length - 1];
    switch (fileType) {
      case 'json':
        return <img src={images.image_json_file} width={60} height={80} />;
      case 'mp4':
        return <img src={images.image_mp4_file} width={60} height={80} />;

      default:
        return <img src={data?.uRL} width={60} height={80} />;
    }
  };

  const renderAction = (value: string) => {
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Copy url to clipoard">
            <IconButton
              aria-label="details"
              onClick={() => {
                onCopy(value);
              }}
              size="medium"
            >
              <Copy style={StyledIcon} />
            </IconButton>
          </Tooltips>
          <Tooltips title="Delete">
            <IconButton
              aria-label="details"
              onClick={() => {
                setSelectedId(dataSource[value]?.id);
                toggleModalConfirmDelete();
              }}
              size="medium"
            >
              <Delete style={StyledIcon} color="error" />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  useEffect(() => {
    getManageAssets();
  }, [params]);

  return (
    <React.Fragment>
      <Helmet title="Manage Assets" />

      <ModalConfirm
        visible={isModalConfirmDelete}
        title={
          <Typography variant="h4">
            Are you sure you want to delete{' '}
            {dataSource.find(item => item?.id === selectedId)?.fileName}?
          </Typography>
        }
        subTitle={<Typography>This action cannot be undone.</Typography>}
        onClose={toggleModalConfirmDelete}
        onConfirm={onDelete}
        isLoading={isLoading}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Manage Assets
          </Typography>
          <Breadcrumbs
            routes={[typeRoutes('View and delete assets', null, true)]}
          />
        </Grid>
      </Grid>
      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        textNodata="There are no assets in this folder."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBody>
            {dataSource?.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={index}
                  tabIndex={-1}
                  onMouseEnter={() => setFocusId(index)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell component="th" scope="row">
                    {renderImage(row)}
                  </TableCell>
                  <TableCell align="left">{row.fileName}</TableCell>
                  <TableCell align="left">
                    {formatLocalDateFromUTCTime(
                      row.createdDateTime,
                      formatDateTime
                    )}
                  </TableCell>
                  <TableCell align="left">{row.owner}</TableCell>
                  <TableCell
                    align="right"
                    padding="checkbox"
                    style={{paddingRight: '10px'}}
                  >
                    {renderAction(index)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
};

export default ManageAssets;
