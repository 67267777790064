/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Edit} from '@mui/icons-material';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {FilterList as FilterListIcon} from '@mui/icons-material';
import {DateRange} from '@mui/lab/DateRangePicker';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {ColorType} from '../../types/typeChip';
import {Breadcrumbs, Button, Chip, Table, Tooltips} from '../../components';
import {path} from '../../routes/path';
import {
  ColumnsType,
  RowType,
  typeRoutes,
} from '../../types/typeVoucherTransactionHistory';
import ModalFilter from './components/ModalFilter/ModalFilter';
import MembershipTicketService from '../../services/MembershipTicket/membership-ticket.service';
import IslandPartnerService from '../../services/Islander/islander.service';
import {
  hasPermission,
  formatDate,
  getEndOfDate,
  getStartOfDate,
  formatLocalDateFromUTCTime,
  envRole,
} from '../../utils';
import {
  MEMBERSHIP_TICKET_COLOR,
  MEMBERSHIP_TICKET_LABEL,
  MEMBERSHIP_TICKET_STATUS,
} from '../../constants/status';
import useAuth from '../../hooks/useAuth';
import ExportExcelService from '../../services/ExportExcel/ExportExcel';
import success from '../../constants/success';
import queryString from 'query-string';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
  StyledStack,
  StyledAction,
  StyledIcon,
} from './VoucherTransactionHistory.style';
import {USER_TYPE} from '../../constants/UserType';
import {
  REDEMPTION_PLATFORM,
  REDEMPTION_PLATFORMS,
  TYPES,
  TYPES_USED_PLATFROM,
  USED_PLATFORM,
  USED_PLATFORM_OPTIONS,
} from '../../constants/redemptionPlatform';

const optionByStatus = (Object.keys(MEMBERSHIP_TICKET_LABEL) || []).reduce(
  (items, key) => {
    items.push({
      value: key,
      label: MEMBERSHIP_TICKET_LABEL[key],
    });
    return items;
  },
  []
);

function VoucherTransactionHistory() {
  const navigate = useNavigate();
  const {getUser, showNotification} = useAuth();
  const locationUrl = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const {merchantId, email} = getUser() || {};
  const role =
    +envRole === USER_TYPE.ADMINISTRATOR ? 'administrator' : 'merchant';
  const canUpdate = hasPermission(path.ISLAND_MEMBERS, 'update');
  const getMerchantId = locationUrl?.state?.merchantId;
  const getMembershipEmail = locationUrl?.state?.membershipEmail;
  const getVoucherId = locationUrl?.state?.voucherId;

  const format = 'DD/MM/YYYY';
  const formatDateTime = 'DD/MM/YYYY HH:mm';

  // states
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [focusId, setFocusId] = useState(null);
  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [nameContainsList, setNameContainsList] = useState([]);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const [valueFilter, setValueFilter] = useState({
    title_eq: searchParams.get('title_eq') ?? '',
    id_eq: searchParams.get('id_eq') ?? '',
    merchantTicketPointId_eq:
      (getMerchantId || searchParams.get('merchantTicketPointId_eq')) ?? '',
    email_eq: (getMembershipEmail || searchParams.get('email_eq')) ?? '',
    voucherStatus_eq: searchParams.get('voucherStatus_eq') ?? '',
    snAppId_eq: searchParams.get('snAppId_eq') ?? '',
    createdDatetime_lte: searchParams.get('createdDatetime_lte') ?? '',
    createdDatetime_gte: searchParams.get('createdDatetime_gte') ?? '',
    updatedDateTime_lte: searchParams.get('updatedDateTime_lte') ?? '',
    updatedDateTime_gte: searchParams.get('updatedDateTime_gte') ?? '',
    updatedDateTime: [null, null],
    createdDatetime: [null, null],
    redeemedFrom_eq: searchParams.get('redeemedFrom_eq') ?? '',
    usagePlatform_eq: searchParams.get('usagePlatform_eq') ?? '',
  });

  const [valueRangePicker, setValueRangePicker] = useState<DateRange<Date>>([
    null,
    null,
  ]);
  const searchParamsObject = queryString.parse(searchParams.toString());
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    _sort: 'updatedDateTime:desc',
    merchantTicketPointId_eq:
      (getMerchantId || searchParamsObject.merchantTicketPointId_eq) ??
      undefined,
    email_eq: (getMembershipEmail || searchParamsObject.email_eq) ?? undefined,
    ticketId_eq: getVoucherId || undefined,
    ...{
      title_eq: searchParamsObject.title_eq ?? undefined,
      id_eq: searchParamsObject.id_eq ?? undefined,
      voucherStatus_eq: searchParamsObject.voucherStatus_eq ?? undefined,
      snAppId_eq: searchParamsObject.snAppId_eq ?? undefined,
      createdDatetime_lte: searchParamsObject.createdDatetime_lte ?? undefined,
      createdDatetime_gte: searchParamsObject.createdDatetime_gte ?? undefined,
      updatedDateTime_lte: searchParamsObject.createdDatetime_lte ?? undefined,
      updatedDateTime_gte: searchParamsObject.createdDatetime_gte ?? undefined,
      redeemedFrom_eq: searchParamsObject.redeemedFrom_eq ?? undefined,
      usagePlatform_eq: searchParamsObject.usagePlatform_eq ?? undefined,
    },
  });

  const onChangeRangePicker = createdDatetime => {
    setValueFilter({
      ...valueFilter,
      createdDatetime,
    });
  };

  const onChangeUsedDateRangePicker = updatedDateTime => {
    setValueFilter({
      ...valueFilter,
      updatedDateTime,
    });
  };

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'membershipNo',
      numeric: false,
      disablePadding: false,
      label: 'Account ID',
    },
    {
      dataIndex: 'ticketNumberId',
      numeric: false,
      disablePadding: false,
      label: 'Redeemed Voucher No.',
    },
    {
      dataIndex: 'ticketTitle',
      numeric: false,
      disablePadding: false,
      label: 'Voucher Title',
    },
    {
      dataIndex: 'ticketPoint',
      numeric: false,
      disablePadding: false,
      label: 'Points Redeemed',
    },
    {
      dataIndex: 'gottenDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Redemption DateTime',
    },
    {
      dataIndex: 'redempltionPlatform',
      numeric: false,
      disablePadding: false,
      label: 'Redemption Platform',
      subText:
        "Platform used (WEB or APP) when member(s) redeemed the voucher. Transactions that occurred before 25 Aug'23 are recorded as 'Unspecified' as platform information was not available prior to this date. For instances where the voucher is automatically distributed by our system, it will be labeled as 'System'.",
    },
    {
      dataIndex: 'updatedDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Usage DateTime',
    },
    {
      dataIndex: 'usedFrom',
      numeric: false,
      disablePadding: false,
      label: 'Usage Platform',
    },
    {
      dataIndex: 'merchantName',
      numeric: false,
      disablePadding: false,
      label: 'Usage at which IP',
    },
    {
      dataIndex: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Voucher Status',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const onClickIconButton = idTransaction => {
    return navigate(`/${path.VOUCHER_TRANSACTION_HISTORY}/${idTransaction}`, {
      state: {
        filterParams: {
          ...queryString.parse(searchParams.toString()),
        },
      },
    });
  };

  const renderAction = (value: string) => {
    if (!canUpdate) {
      if (focusId === value) {
        return (
          <StyledAction>
            <Tooltips title="View detail">
              <IconButton
                aria-label="details"
                size="medium"
                onClick={() => {
                  onClickIconButton(value);
                }}
              >
                <VisibilityIcon style={StyledIcon} />
              </IconButton>
            </Tooltips>
          </StyledAction>
        );
      }
    }
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                onClickIconButton(value);
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const renderStatus = value => {
    if (value) {
      const statusWithPointId =
        value.pointId === null
          ? MEMBERSHIP_TICKET_STATUS.ACTIVE
          : MEMBERSHIP_TICKET_STATUS.USED;
      let renderColor = MEMBERSHIP_TICKET_COLOR[statusWithPointId];
      let label = MEMBERSHIP_TICKET_LABEL[statusWithPointId];
      if (
        (value?.usedFrom === TYPES_USED_PLATFROM.WEB_MANUAL ||
          value?.usedFrom === TYPES_USED_PLATFROM.APP_MANUAL) &&
        statusWithPointId !== MEMBERSHIP_TICKET_STATUS.ACTIVE &&
        value?.status === MEMBERSHIP_TICKET_STATUS.ACTIVE
      ) {
        label = MEMBERSHIP_TICKET_LABEL[MEMBERSHIP_TICKET_STATUS.USED_MANUAL];
        renderColor =
          MEMBERSHIP_TICKET_COLOR[MEMBERSHIP_TICKET_STATUS.USED_MANUAL];
      }
      return <Chip label={label} color={renderColor as ColorType} />;
    }
  };

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportEmailExcelVoucherTransaction({
        ...params,
        email: email,
      })
      .then(res => {
        setIsLoadingExport(false);
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const handleDeleteChip = (type: string) => {
    if (type === 'title_eq') {
      deleteSearchParams('title_eq');
      setValueFilter({
        ...valueFilter,
        title_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        title_eq: undefined,
      }));
    }

    if (type === 'id_eq') {
      deleteSearchParams('id_eq');
      setValueFilter({
        ...valueFilter,
        id_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        id_eq: undefined,
      }));
    }

    if (type === 'merchantTicketPointId_eq') {
      deleteSearchParams('merchantTicketPointId_eq');
      deleteSearchParams('merchantTicketPointId_label');
      setValueFilter({
        ...valueFilter,
        merchantTicketPointId_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        merchantTicketPointId_eq: undefined,
      }));
    }

    if (type === 'snAppId_eq') {
      deleteSearchParams('snAppId_eq');
      setValueFilter({
        ...valueFilter,
        snAppId_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        snAppId_eq: undefined,
      }));
    }

    if (type === 'email_eq') {
      deleteSearchParams('email_eq');
      setValueFilter({
        ...valueFilter,
        email_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        email_eq: undefined,
      }));
    }

    if (type === 'redeemedFrom_eq') {
      deleteSearchParams('redeemedFrom_eq');
      setValueFilter({
        ...valueFilter,
        redeemedFrom_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        redeemedFrom_eq: undefined,
      }));
    }

    if (type === 'voucherStatus_eq') {
      deleteSearchParams('voucherStatus_eq');
      setValueFilter({
        ...valueFilter,
        voucherStatus_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        voucherStatus_eq: undefined,
      }));
    }

    if (type === 'usagePlatform_eq') {
      deleteSearchParams('usagePlatform_eq');
      setValueFilter({
        ...valueFilter,
        usagePlatform_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        usagePlatform_eq: undefined,
      }));
    }

    if (type === 'createdDatetime') {
      deleteSearchParams('createdDatetime_lte');
      deleteSearchParams('createdDatetime_gte');
      setValueFilter({
        ...valueFilter,
        createdDatetime_lte: '',
        createdDatetime_gte: '',
        createdDatetime: [null, null],
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        createdDatetime_gte: undefined,
        createdDatetime_lte: undefined,
      }));
    }

    if (type === 'updatedDateTime') {
      deleteSearchParams('updatedDateTime_lte');
      deleteSearchParams('updatedDateTime_gte');
      setValueFilter({
        ...valueFilter,
        updatedDateTime_lte: '',
        updatedDateTime_gte: '',
        updatedDateTime: [null, null],
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        updatedDateTime_gte: undefined,
        updatedDateTime_lte: undefined,
      }));
    }
  };

  const toggleModal = () => {
    setIsModalFilter(!isModalFilter);
  };

  const handleClean = () => {
    setValueFilter({
      title_eq: '',
      id_eq: '',
      merchantTicketPointId_eq: '',
      email_eq: '',
      voucherStatus_eq: '',
      snAppId_eq: '',
      createdDatetime_lte: '',
      createdDatetime_gte: '',
      createdDatetime: [null, null],
      redeemedFrom_eq: '',
      usagePlatform_eq: '',
      updatedDateTime_lte: '',
      updatedDateTime_gte: '',
      updatedDateTime: [null, null],
    });
  };

  const handleFilter = () => {
    if (valueFilter?.usagePlatform_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        usagePlatform_eq:
          Number(valueFilter?.usagePlatform_eq) === 0
            ? undefined
            : valueFilter?.usagePlatform_eq,
      }));
    }
    if (valueFilter?.title_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        title_eq: valueFilter?.title_eq,
      }));
    }
    if (valueFilter?.id_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        id_eq: valueFilter?.id_eq,
      }));
    }

    if (valueFilter?.merchantTicketPointId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        merchantTicketPointId_eq:
          valueFilter?.merchantTicketPointId_eq === 'All IP'
            ? undefined
            : valueFilter?.merchantTicketPointId_eq,
      }));
    }

    if (valueFilter?.snAppId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        snAppId_eq: valueFilter?.snAppId_eq,
      }));
    }

    if (valueFilter?.email_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        email_eq: valueFilter?.email_eq,
      }));
    }

    if (valueFilter?.redeemedFrom_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        redeemedFrom_eq: valueFilter?.redeemedFrom_eq,
      }));
    }

    if (valueFilter?.voucherStatus_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        voucherStatus_eq: valueFilter?.voucherStatus_eq,
      }));
    }
    if (valueFilter?.updatedDateTime[0] || valueFilter?.updatedDateTime[1]) {
      let obj = {};
      if (valueFilter?.updatedDateTime[0]) {
        obj = {
          ...obj,
          updatedDateTime_gte: getStartOfDate(valueFilter.updatedDateTime[0]),
        };
      }
      if (valueFilter?.updatedDateTime[1]) {
        obj = {
          ...obj,
          updatedDateTime_lte: getEndOfDate(valueFilter?.updatedDateTime[1]),
        };
      }
      setParams(preState => ({
        ...preState,
        _page: 0,
        ...obj,
      }));
    } else if (
      valueFilter?.updatedDateTime_lte !== '' &&
      valueFilter?.updatedDateTime_gte !== ''
    ) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        updatedDateTime_lte: valueFilter?.updatedDateTime_lte,
        updatedDateTime_gte: valueFilter?.updatedDateTime_gte,
      }));
    }

    if (valueFilter?.createdDatetime[0] || valueFilter?.createdDatetime[1]) {
      let obj = {};
      if (valueFilter?.createdDatetime[0]) {
        obj = {
          ...obj,
          createdDatetime_gte: getStartOfDate(valueFilter.createdDatetime[0]),
        };
      }
      if (valueFilter?.createdDatetime[1]) {
        obj = {
          ...obj,
          createdDatetime_lte: getEndOfDate(valueFilter?.createdDatetime[1]),
        };
      }
      setParams(preState => ({
        ...preState,
        _page: 0,
        ...obj,
      }));
    } else if (
      valueFilter?.createdDatetime_lte !== '' &&
      valueFilter?.createdDatetime_gte !== ''
    ) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        createdDatetime_lte: valueFilter?.createdDatetime_lte,
        createdDatetime_gte: valueFilter?.createdDatetime_gte,
      }));
    }
    toggleModal();
    // handleClean();
  };

  const getListVoucherTransactionHistory = () => {
    setIsLoading(true);
    if (role) {
      new MembershipTicketService()
        .getAll({
          ...params,
        })
        .then(res => {
          if (res?.data && Array.isArray(res.data.items)) {
            setDataSource(res.data.items);
            setTotalPage(res?.data?.total);
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
          // Get api error => show notification or no items listing
          setError(error);
        });
    }
  };

  const renderTicketCategory = (categories: any) => {
    return categories.map(item => item?.title).join(', ');
  };

  const renderRedemptionPlatform = (type: number) => {
    return REDEMPTION_PLATFORM[type] || '--';
  };

  const renderUsedPlatform = (usedFrom: number) => {
    if (usedFrom === TYPES_USED_PLATFROM.WEB_MANUAL) {
      usedFrom = TYPES_USED_PLATFROM.WEB;
    }

    if (usedFrom === TYPES_USED_PLATFROM.APP_MANUAL) {
      usedFrom = TYPES_USED_PLATFROM.APP;
    }
    return USED_PLATFORM_OPTIONS[usedFrom] || '--';
  };

  const renderMerchantLabel = () => {
    if (
      nameContainsList.length === 0 &&
      searchParams.get('merchantTicketPointId_label') !== null
    ) {
      return searchParams.get('merchantTicketPointId_label');
    }
    return valueFilter?.merchantTicketPointId_eq !== ''
      ? nameContainsList
          .concat({label: 'All IP', value: 'All IP'})
          ?.find(item => item.value === valueFilter?.merchantTicketPointId_eq)
          ?.label
      : undefined;
  };

  useEffect(() => {
    // populate nameContainsList
    new IslandPartnerService()
      .getAll({
        id_eq: role === 'merchant' ? merchantId : undefined,
        voucherStatus_eq: 1,
        _size: 2147483647,
      })
      .then(res => {
        if (res?.data?.items) {
          setNameContainsList(
            res?.data?.items?.reduce((items, item) => {
              items.push({
                label: item.name || '-',
                value: item.id,
              });
              return items;
            }, [])
          );
        }
      });
  }, []);

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
        merchantTicketPointId_label: renderMerchantLabel(),
        redemptionPlatform_label: params?.redeemedFrom_eq
          ? REDEMPTION_PLATFORMS[params?.redeemedFrom_eq - 1]
          : undefined,
      })
    );
    getListVoucherTransactionHistory();
  }, [params, role]);

  return (
    <React.Fragment>
      <Helmet title="Voucher Transaction" />

      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModal}
        handleFilter={handleFilter}
        handleClean={handleClean}
        valueFilter={valueFilter}
        valueRangePicker={valueRangePicker}
        setValueFilter={setValueFilter}
        setValueRangePicker={setValueRangePicker}
        optionByStatus={optionByStatus}
        nameContainsList={nameContainsList}
        setNameContainsList={setNameContainsList}
        onChangeRangePicker={onChangeRangePicker}
        onChangeUsedDateRangePicker={onChangeUsedDateRangePicker}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Voucher Transaction
          </Typography>
          <Breadcrumbs
            routes={[typeRoutes('Voucher Transaction', null, true)]}
          />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          {/* <ButtonExport
            params={params}
            exportMethod={
              new ExportExcelService().exportExcelVoucherTransaction
            }
            fileName="VOUCHER_TRANSACTION_HISTORY_REPORT"
          /> */}

          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>

          <StyledStack direction="row" spacing={3}>
            {searchParams.get('title_eq') !== null && (
              <Chip
                label={searchParams.get('title_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('title_eq');
                }}
              />
            )}

            {searchParams.get('redeemedFrom_eq') !== null && (
              <Chip
                label={searchParams.get('redemptionPlatform_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('redeemedFrom_eq');
                }}
              />
            )}

            {searchParams.get('id_eq') !== null && (
              <Chip
                label={searchParams.get('id_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('id_eq');
                }}
              />
            )}

            {searchParams.get('email_eq') !== null && (
              <Chip
                label={searchParams.get('email_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('email_eq');
                }}
              />
            )}

            {searchParams.get('merchantTicketPointId_label') !== null && (
              <Chip
                label={searchParams.get('merchantTicketPointId_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('merchantTicketPointId_eq');
                }}
              />
            )}

            {searchParams.get('voucherStatus_eq') !== null && (
              <Chip
                label={
                  MEMBERSHIP_TICKET_LABEL[
                    Number(searchParams.get('voucherStatus_eq'))
                  ]
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('voucherStatus_eq');
                }}
              />
            )}

            {searchParams.get('snAppId_eq') !== null && (
              <Chip
                label={searchParams.get('snAppId_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('snAppId_eq');
                }}
              />
            )}

            {searchParams.get('usagePlatform_eq') !== null && (
              <Chip
                label={
                  USED_PLATFORM[Number(searchParams.get('usagePlatform_eq'))]
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('usagePlatform_eq');
                }}
              />
            )}

            {searchParams.get('createdDatetime_lte') !== null &&
              searchParams.get('createdDatetime_gte') !== null && (
                <Chip
                  label={`${formatDate(
                    searchParams.get('createdDatetime_gte'),
                    format
                  )} -
                    ${formatDate(
                      searchParams.get('createdDatetime_lte'),
                      format
                    )}`}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('createdDatetime');
                  }}
                />
              )}

            {searchParams.get('updatedDateTime_lte') !== null &&
              searchParams.get('updatedDateTime_gte') !== null && (
                <Chip
                  label={`${formatDate(
                    searchParams.get('updatedDateTime_gte'),
                    format
                  )} -
                    ${formatDate(
                      searchParams.get('updatedDateTime_lte'),
                      format
                    )}`}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('updatedDateTime');
                  }}
                />
              )}
          </StyledStack>
          <Tooltips title="Filter list">
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModal}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        textNodata="There are no voucher transactions that match the filter."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBody>
            {dataSource?.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={index}
                  tabIndex={-1}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell align="left">{row?.membershipNo}</TableCell>
                  <TableCell component="th" scope="row">
                    000{row.id}
                  </TableCell>
                  <TableCell align="left">{row.ticketTitle}</TableCell>
                  <TableCell align="left">{row.ticketPoint}</TableCell>
                  <TableCell align="left">
                    {formatLocalDateFromUTCTime(
                      row.createdDateTime,
                      formatDateTime
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {renderRedemptionPlatform(row.redeemedFrom)}
                  </TableCell>
                  <TableCell align="left">
                    {formatLocalDateFromUTCTime(
                      row.updatedDateTime,
                      formatDateTime
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {renderUsedPlatform(row?.usedFrom)}
                  </TableCell>
                  <TableCell align="left">
                    {row.merchantName ? row.merchantName : '--'}
                  </TableCell>
                  <TableCell align="left">{renderStatus(row)}</TableCell>
                  <TableCell
                    align="right"
                    padding="checkbox"
                    style={{paddingRight: '10px'}}
                  >
                    {renderAction(row.id)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
}

export default VoucherTransactionHistory;
