/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode, useEffect, useState} from 'react';
import {IconButton, Tooltip, Typography, Stack} from '@mui/material';
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from '@mui/icons-material';
import Button from '../../../components/Button/Button';

// style
import {
  Spacer,
  StyledHeader,
  StyledToolbar,
  StyleItemFilter,
} from './EnhancedTableToolbar.style';

export type ListFilterProps = {
  label?: string;
  key?: string | number;
};

export type ParamsProps = {
  _page?: number;
  _size?: number;
  status_eq?: number;
};

type EnhancedTableToolbarProps = {
  numSelected?: number;
  isHeader?: boolean;
  isFilter?: boolean;
  childrenHeader?: ReactNode;
  isAddNew?: boolean;
  onOpenFilter?: () => void;
  onClickAddNew?: () => void;
  setTypeFilter?: (any) => void;
  titleHeader?: string;
  isLoading?: boolean;
  setParams?: (ParamsProps) => void;
  canCreate?: boolean;
  isDisabledAddNew?: boolean;
  enableDeletedTab?: boolean;
};

function EnhancedTableToolbar({
  numSelected,
  onOpenFilter,
  isHeader,
  titleHeader = '',
  isFilter = false,
  isAddNew = false,
  childrenHeader,
  onClickAddNew,
  isLoading,
  setParams,
  canCreate,
  isDisabledAddNew = false,
  enableDeletedTab = false,
}: EnhancedTableToolbarProps) {
  const getCurrentDate = new Date().toISOString();

  const [listFilter, setListFilter] = useState<ListFilterProps[]>([
    {
      label: 'All',
      key: 'All',
    },
    {
      label: 'Live',
      key: 'Live',
    },
    {
      label: 'Inactive',
      key: 'Inactive',
    },
  ]);

  const [activeFilter, setActiveFilter] = useState<ListFilterProps>({
    label: 'All',
    key: 'All',
  });

  const onClickFilter = value => {
    if (value) {
      const findData = listFilter.find(item => item.key === value);
      setActiveFilter(findData);
      switch (findData.key) {
        case 'Live':
          return setParams(preState => ({
            ...preState,
            _page: 0,
            isPublished_eq: true,
          }));
        case 'Inactive':
          return setParams(preState => ({
            ...preState,
            _page: 0,
            isPublished_eq: false,
          }));
        default:
          setParams(preState => ({
            ...preState,
            _page: 0,
            isPublished_eq: undefined,
          }));
      }
    }
    return null;
  };

  return (
    <StyledHeader>
      {isHeader && (
        <StyledToolbar>
          {isAddNew && !titleHeader && (
            <Stack direction="row" spacing={3} mt={4} mb={4}>
              {listFilter.map((item, key) => (
                <StyleItemFilter
                  key={key}
                  className={`${
                    activeFilter.key === item.key ? 'active' : 'inactive'
                  }`}
                  onClick={() => {
                    onClickFilter(item.key);
                  }}
                >
                  {item.label}
                </StyleItemFilter>
              ))}
            </Stack>
          )}
          <Spacer />
          {isFilter && (
            <div>
              {numSelected > 0 ? (
                <Tooltip title="Delete">
                  <IconButton aria-label="Delete" size="large">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Filter list">
                  <IconButton
                    aria-label="Filter list"
                    size="large"
                    onClick={onOpenFilter}
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
          {isAddNew && (
            <div
              style={{
                width: '100%',
                textAlign: 'right',
              }}
            >
              {!!canCreate && (
                <Button
                  onClick={onClickAddNew}
                  type="button"
                  loading={isLoading}
                  disabled={isDisabledAddNew}
                >
                  Add new
                </Button>
              )}
            </div>
          )}
        </StyledToolbar>
      )}
    </StyledHeader>
  );
}
export default EnhancedTableToolbar;
