/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {images} from '../../../../constants/image';
import {
  Modal,
  Select,
  Button,
  Input,
  RangePicker,
} from '../../../../components';
import IslandPartnerService from '../../../../services/Islander/islander.service';
import useAuth from '../../../../hooks/useAuth';
import {envRole} from '../../../../utils';
import {USER_TYPE} from '../../../../constants/UserType';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';
import {
  REDEMPTION_PLATFORM,
  USED_PLATFORM_OPTIONS,
} from '../../../../constants/redemptionPlatform';

interface ModalFilterProps {
  visible: boolean;
  onClose?: () => void;
  handleClean?: () => void;
  handleFilter?: () => void;
  valueFilter?: any;
  valueRangePicker?: any;
  setValueFilter?: (any) => void;
  setValueRangePicker?: (any) => void;
  setOptionByStatus?: (any) => void;
  optionByStatus?: any;
  nameContainsList?: any;
  setNameContainsList?: (any) => void;
  onChangeRangePicker?: (any) => void;
  onChangeUsedDateRangePicker?: (any) => void;
}

function ModalFilter({
  visible,
  onClose,
  handleClean,
  handleFilter,
  valueFilter,
  setValueFilter,
  optionByStatus,
  nameContainsList = [],
  setNameContainsList,
  onChangeRangePicker,
  onChangeUsedDateRangePicker,
}: ModalFilterProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [gotIslandPartner, setGotIslandPartner] = useState(false);
  const {getUser} = useAuth();
  const {merchantId} = getUser() || {};
  const role =
    +envRole === USER_TYPE.ADMINISTRATOR ? 'administrator' : 'merchant';
  const optionRedemptionPlatform = (
    Object.keys(REDEMPTION_PLATFORM) || []
  ).reduce((items, key) => {
    items.push({
      value: key,
      label: REDEMPTION_PLATFORM[key],
    });
    return items;
  }, []);

  const optionUsedPlatform = (Object.keys(USED_PLATFORM_OPTIONS) || []).reduce(
    (items, key) => {
      items.push({
        value: key,
        label: USED_PLATFORM_OPTIONS[key],
      });
      return items;
    },
    []
  );

  const onChange = (name: string, event: any) => {
    setValueFilter((prevState: any) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  const sortData = data => {
    return data.sort((a, b) => {
      if (a.label > b) return 1;
      if (a.label < b) return -1;
      return 0;
    });
  };

  const getListConfiguration = () => {
    new IslandPartnerService()
      .getAll({
        id_eq: role === 'merchant' ? merchantId : undefined,
        status_eq: 1,
        _size: 2147483647,
        _sort: 'name:asc',
      })
      .then(res => {
        if (res?.data?.items) {
          setNameContainsList(
            res?.data?.items?.reduce((items, item) => {
              items.push({
                label: item.name || '-',
                value: item.id,
              });
              return items;
            }, [])
          );
        }
        setGotIslandPartner(true);
      })
      .catch(() => {
        setGotIslandPartner(true);
      });
  };

  useEffect(() => {
    if (visible) {
      setGotIslandPartner(false);
      setIsLoading(true);
      getListConfiguration();
    }
  }, [visible]);

  useEffect(() => {
    if (gotIslandPartner) {
      setIsLoading(false);
    }
  }, [gotIslandPartner]);

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY ACCOUNT ID
            </Typography>
            <Input
              type="text"
              placeholder="Enter Account ID"
              size="small"
              variant="outlined"
              value={valueFilter.snAppId_eq}
              onChange={e => onChange('snAppId_eq', e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY REDEEMED VOUCHER NO.
            </Typography>
            <Input
              type="number"
              placeholder="Enter Redeemed Voucher No."
              size="small"
              variant="outlined"
              value={valueFilter.id_eq}
              onChange={e => onChange('id_eq', e)}
            />
          </FormControl>
          {/* <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY ISLANDER MEMBER’S EMAIL
            </Typography>
            <Input
              type="email"
              placeholder="Enter Islander Member's Email"
              size="small"
              variant="outlined"
              value={valueFilter.email_eq}
              onChange={e => onChange('email_eq', e)}
            />
          </FormControl> */}
          {/* <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY ISLAND PARTNER’S NAME
            </Typography>
            <Select
              labelId="merchantTicketPointId_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange('merchantTicketPointId_eq', e)}
              value={valueFilter.merchantTicketPointId_eq}
              placeholder="Select Island Partner's Name"
              size="small"
              selectOptions={[
                {
                  label: 'All IP',
                  value: 'All IP',
                },
              ].concat(nameContainsList)}
            />
          </FormControl> */}
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY REDEMPTION PLATFORM
            </Typography>
            <Select
              labelId="redeemedFrom_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange('redeemedFrom_eq', e)}
              value={valueFilter.redeemedFrom_eq}
              placeholder="Select Redemption Platform"
              size="small"
              selectOptions={optionRedemptionPlatform}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY REDEMPTION DATE RANGE
            </Typography>
            <RangePicker
              value={valueFilter.createdDatetime}
              onChange={onChangeRangePicker}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY USAGE DATE RANGE
            </Typography>
            <RangePicker
              value={valueFilter.updatedDateTime}
              onChange={onChangeUsedDateRangePicker}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY USAGE PLATFORM
            </Typography>
            <Select
              labelId="usagePlatform_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange('usagePlatform_eq', e)}
              value={valueFilter.usagePlatform_eq}
              placeholder="Select Usage Platform"
              size="small"
              selectOptions={optionUsedPlatform}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY VOUCHER STATUS
            </Typography>
            <Select
              labelId="voucherStatus_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange('voucherStatus_eq', e)}
              value={valueFilter.voucherStatus_eq}
              placeholder="Select Voucher Status"
              size="small"
              selectOptions={optionByStatus}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button variant="text" onClick={handleClean} loading={isLoading}>
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button
              onClick={handleFilter}
              loading={isLoading}
              disabled={
                !valueFilter?.title_eq &&
                !valueFilter?.id_eq &&
                !valueFilter?.email_eq &&
                !valueFilter?.merchantTicketPointId_eq &&
                !valueFilter?.voucherStatus_eq &&
                !valueFilter?.snAppId_eq &&
                !valueFilter?.redeemedFrom_eq &&
                (!valueFilter.createdDatetime[0] ||
                  !valueFilter.createdDatetime[1]) &&
                (!valueFilter.updatedDateTime[0] ||
                  !valueFilter.updatedDateTime[1]) &&
                !valueFilter?.usagePlatform_eq
              }
            >
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
