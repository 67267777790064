/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Delete, Edit} from '@mui/icons-material';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {ColumnsType, typeRoutes} from '../../types/typeAllTransaction';
import {Breadcrumbs, ModalConfirm, Table, Tooltips} from '../../components';
import EnhancedTableToolbar from './EnhancedTableToolbar/EnhancedTableToolbar';
import {images} from '../../constants/image';
import useAuth from '../../hooks/useAuth';
import VoucherCategoryService from '../../services/Voucher/voucher-category.service';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
  StyledAction,
  StyledIcon,
} from './VoucherCategories.style';
import ModalUpdate from './ModalUpdate/ModalUdate';

const VoucherCategories = (props: any) => {
  // states
  const {showNotification} = useAuth();
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [focusId, setFocusId] = useState(null);
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [isModalConfirmDelete, setIsModalConfirmDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [shopCategories, setShopCategories] = useState<any[]>([]);
  const [icons, setIcons] = useState<any[]>([]);
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    _sort: 'CreatedDateTime:desc',
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'id',
      numeric: false,
      disablePadding: false,
      textAlign: 'center',
      label: 'ID',
    },
    {
      dataIndex: 'tilte',
      numeric: false,
      disablePadding: false,
      label: 'Title',
    },
    {
      dataIndex: 'iconActive',
      numeric: false,
      disablePadding: false,
      label: 'Icon Asset - Active',
    },
    {
      dataIndex: 'iconInactive',
      numeric: false,
      disablePadding: false,
      label: 'Icon Asset - Inactive',
    },
    {
      dataIndex: 'category',
      numeric: false,
      disablePadding: false,
      label: 'Sitecore’s Shop category',
    },
    {
      dataIndex: 'showOnMobileDashboardGuestMode',
      numeric: false,
      disablePadding: false,
      textAlign: 'center',
      label: 'Display on <br/> App Dashboard’s Discovery section (Guest mode)',
    },
    {
      dataIndex: 'showOnMobileDashboardLoggedIn',
      numeric: false,
      disablePadding: false,
      textAlign: 'center',
      label:
        'Display on <br/> App Dashboard’s Discovery section (Logged In mode)',
    },
    {
      dataIndex: 'showOnMobileVoucherListing',
      numeric: false,
      disablePadding: false,
      textAlign: 'center',
      label: 'Display on <br/> App Dashboard’s Listing filter',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const getVoucherCategories = () => {
    setIsLoading(true);
    new VoucherCategoryService().getAll({...params}).then(res => {
      if (res?.success) {
        setDataSource(res?.data?.items);
        setTotalPage(res?.data?.total);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const renderNameFileSVG = (url: string) => {
    const parts = url?.split('/');
    const nameFile = parts?.[parts.length - 1]?.split('?')?.[0];
    return nameFile || '';
  };

  const toggleModalConfirmDelete = () => {
    setIsModalConfirmDelete(!isModalConfirmDelete);
  };

  const toggleModalUpdate = () => {
    setIsModalUpdate(!isModalUpdate);
  };

  const onDelete = () => {
    setIsLoading(true);
    new VoucherCategoryService().softDelete(selectedId).then(res => {
      if (res && res?.success) {
        showNotification({
          message: 'Deleted successfuly',
        });
        toggleModalConfirmDelete();
        getVoucherCategories();
      } else {
        showNotification({
          message: res.errorMessage,
          variation: 'error',
        });
      }
    });
  };

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const renderYesNo = value => {
    switch (value) {
      case false:
        return 'No';
      case true:
        return 'Yes';
      default:
        return 'No';
    }
  };

  const renderAction = (value: string) => {
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                toggleModalUpdate();
                setSelectedId(dataSource[value]?.id);
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
          {dataSource[value]?.status === 1 && (
            <Tooltips title="Deactivate">
              <IconButton
                aria-label="details"
                onClick={() => {
                  setSelectedId(dataSource[value]?.id);
                  toggleModalConfirmDelete();
                }}
                size="medium"
              >
                <Delete style={StyledIcon} color="error" />
              </IconButton>
            </Tooltips>
          )}
        </StyledAction>
      );
    }
  };

  const getSvgFileName = (url: string) => {
    const urlParts = url?.split('/');
    const lastPart = urlParts?.[urlParts?.length - 1];
    const fileNameWithQueryParams = lastPart?.split('?')[0];
    return fileNameWithQueryParams;
  };

  const GetCategories = () => {
    new VoucherCategoryService().getSirecoreCategory().then(res => {
      if (res.status === 200) {
        const newCategories: any[] = res?.data?.data?.map((item, index) => ({
          value: index + 1,
          label: item?.label,
          id: item?.id,
        }));
        setShopCategories([
          {
            value: 0,
            label: '---',
            id: null,
          },
          ...newCategories,
        ]);
        let listIcon: any[] = [];
        res?.data?.data?.map((item, index) => {
          listIcon = [
            ...listIcon,
            {
              value: index,
              label: getSvgFileName(item?.iconActive),
              image: item?.iconActive,
              width: 48,
              height: 32,
            },
          ];
          listIcon = [
            ...listIcon,
            {
              image: item?.iconInActive,
              value: index,
              label: getSvgFileName(item?.iconInActive),
              width: 48,
              height: 32,
            },
          ];
        });
        setIcons(listIcon);
      }
    });
  };

  useEffect(() => {
    GetCategories();
  }, []);

  useEffect(() => {
    getVoucherCategories();
  }, [params]);

  return (
    <React.Fragment>
      <Helmet title="Voucher Categories" />
      <ModalUpdate
        visible={isModalUpdate}
        onClose={toggleModalUpdate}
        reloadPage={getVoucherCategories}
        icons={icons}
        shopCategories={shopCategories}
        idDetail={selectedId}
      />
      <ModalConfirm
        visible={isModalConfirmDelete}
        icon={images.image_deactivate}
        title={
          <Typography variant="h4">
            Are you sure you want to deactivate this category?
          </Typography>
        }
        subTitle={
          <Typography>
            Please note that this change will take effect instantly on App. You
            can always reactivate it.
          </Typography>
        }
        onClose={toggleModalConfirmDelete}
        onConfirm={onDelete}
        isLoading={isLoading}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Voucher Categories
          </Typography>
          <Breadcrumbs routes={[typeRoutes('Manage categories', null, true)]} />
        </Grid>
      </Grid>
      <Divider my={6} />
      <EnhancedTableToolbar
        isHeader={true}
        isLoading={isLoading}
        isAddNew
        setParams={setParams}
      />
      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        textNodata="There are no assets in this folder."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBody>
            {dataSource?.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={index}
                  tabIndex={-1}
                  onMouseEnter={() => setFocusId(index)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell component="th" scope="row">
                    {row?.id}
                  </TableCell>
                  <TableCell align="left">{row?.title}</TableCell>
                  <TableCell align="left">
                    {renderNameFileSVG(row?.iconActive)}
                  </TableCell>
                  <TableCell align="left">
                    {renderNameFileSVG(row?.iconInactive)}
                  </TableCell>
                  <TableCell align="left">
                    {row?.associatedSitecoreShopCategory}
                  </TableCell>
                  <TableCell align="center">
                    {renderYesNo(row?.showOnMobileDashboardGuestMode)}
                  </TableCell>
                  <TableCell align="center">
                    {renderYesNo(row?.showOnMobileDashboardLoggedIn)}
                  </TableCell>
                  <TableCell align="center">
                    {renderYesNo(row?.showOnMobileVoucherListing)}
                  </TableCell>
                  <TableCell
                    align="right"
                    padding="checkbox"
                    style={{paddingRight: '10px'}}
                  >
                    {renderAction(index)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
};

export default VoucherCategories;
