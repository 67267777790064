import BaseApiService from '../baseApi.service';

export default class MembersService extends BaseApiService {
  readonly basePath: string = 'memberships/v1';
  constructor(props = null) {
    super(props);
  }

  getListTier = async (params: object) => {
    return await this.get('tiers', params).then(async res => res?.data);
  };

  getTerminatedMembers = async (params: object) => {
    return await this.get('get-terminated-members', params).then(res => res);
  };
}
