import React from 'react';
import {path} from './path';

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from '../components/Guards/AuthGuard';
// Layouts
import AuthLayout from '../layouts/Auth';
import DashboardLayout from '../layouts/Dashboard';
import IslandPartner from '../pages/island-partner/IslandPartner';
import SDCAdministrators from '../pages/sdc-administrators/SDCAdministrators';
import IslandMembers from '../pages/islander-members/IslanderMembers';
import AllTransaction from '../pages/all-transaction/AllTransaction';
import VoucherTransactionHistory from '../pages/voucher-transaction-history/VoucherTransactionHistory';
import Dashboard from '../pages/dashboard/Dashboard';
import Voucher from '../pages/voucher/Voucher';
import Event from '../pages/event/Event';
import Article from '../pages/article/Article';
import FeaturedVoucher from '../pages/featured-voucher/FeaturedVoucher';
import AuditLogs from '../pages/audit-logs/AuditLogs';
import AuditLogsMembers from '../pages/audit-logs-members/AuditLogsMembers';
import WatchlistTags from '../pages/watchlist-tags/WatchlistTags';
import Partnership from '../pages/referral-programme/partnership/Partnership';
import ReferralTransactions from '../pages/referral-programme/referral-transactions/ReferralTransactions';
import ReferralIslanderMember from '../pages/referral-programme/islander-member/IslanderMember';

// detail
import SDCAdministratorsDetail from '../pages/sdc-administrators/SDCAdministratorsDetail/SDCAdministratorsDetail';
import IslanderMembersDetail from '../pages/islander-members/IslanderMemberDetail/IslanderMemberDetail';
import IslandPartnerDetail from '../pages/island-partner/IslandPartnerDetail/IslandPartnerDetail';
import AllTransactionDetail from '../pages/all-transaction/AllTransactionDetail/AllTransactionDetail';
import VoucherTransactionHistoryDetail from '../pages/voucher-transaction-history/VoucherTransactionHistoryDetail/VoucherTransactionHistoryDetail';
import VoucherDetail from '../pages/voucher/VoucherDetail/VoucherDetail';
import PartnershipDetail from '../pages/referral-programme/partnership/PartnershipDetail/PartnershipDetail';
import ReferralTransactionDetail from '../pages/referral-programme/referral-transactions/ReferralTransactionDetail/ReferralTransactionDetail';

// Auth components
import SignIn from '../pages/auth/SignIn/SignIn';
import SignUp from '../pages/auth/SignUp/SignUp';
import ResetPassword from '../pages/auth/ResetPassword/ResetPassword';
import ResetPasswordSuccess from '../pages/auth/ResetPasswordSuccess/ResetPasswordSuccess';
import ChangePasswordSuccess from '../pages/auth/ChangePasswordSuccess/ChangePasswordSuccess';

// User
import UserResetPassword from '../pages/user/ResetPassword/ResetPassword';

import Page404 from '../pages/auth/Page404/Page404';
import Page500 from '../pages/auth/Page500/Page500';
import ResetPasswordExpired from '../pages/auth/ResetPasswordExpired/ResetPasswordExpired';
import FAVerification from '../pages/auth/2FAVerification/2FAVerification';
import ForgotPassword from '../pages/auth/ForgotPassword/ForgotPassword';
import ChangeLog from '../pages/change-log/ChangeLog';
import LimitedEvent from '../pages/limited-events/LimitedEvent';
import ManageAssets from '../pages/manage-assets/ManageAssets';
import UploadNew from '../pages/upload-new/UploadNew';
import MemberDashboard from '../pages/member-dashboard/MemberDashboard';
import MemberDashboardCreateNew from '../pages/member-dashboard/CreateNew/MemberDashboardCreateNew';
import VoucherCategories from '../pages/voucher-categories/VoucherCategories';
import AddNew from '../pages/voucher-categories/AddNew/AddNew';
import TerminatedMembers from '../pages/terminated-members/TerminatedMembers';

const withAuth = content => <AuthGuard>{content}</AuthGuard>;

const routes = [
  {
    path: path.USER,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.RESET_PASSWORD,
        element: <UserResetPassword />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.DASHBOARD,
        element: <Dashboard />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.SDC_ADMINISTRATORS,
        element: <SDCAdministrators />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.ISLAND_MEMBERS,
        element: <IslandMembers />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.TERMINATED_MEMBERS,
        element: <TerminatedMembers />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.WATCHLIST_TAG,
        element: <WatchlistTags />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.ISLAND_PARTNERS,
        element: <IslandPartner />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.ISLAND_PARTNERS}?merchantId=:id`,
        element: <AllTransaction />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.ALL_TRANSACTION,
        element: <AllTransaction />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.LIMITED_EVENT,
        element: <LimitedEvent />,
      },
    ],
  },
  {
    path: `${path.MOBILE_APP_CMS}/${path.MEMBER_DASHBOARD}`,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.MEMBER_DASHBOARD_CREATE_NEW,
        element: <MemberDashboardCreateNew />,
      },
      {
        path: path.MEMBER_DASHBOARD_MANAGE_LIVE,
        element: <MemberDashboard />,
      },
    ],
  },
  {
    path: path.MOBILE_APP_CMS,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.ARTICLE,
        element: <Article />,
      },
      {
        path: path.EVENTS,
        element: <Event />,
      },
    ],
  },
  {
    path: `${path.MOBILE_APP_CMS}/${path.MEDIA_LIBRARY}`,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.UPLOAD_NEW,
        element: <UploadNew />,
      },
      {
        path: path.MOBILE_ASSETS,
        element: <ManageAssets />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.ALL_TRANSACTION}?membershipId=:id`,
        element: <AllTransaction />,
      },
    ],
  },
  {
    path: path.REFERRAL_PROGRAMME,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.PARTNERSHIP}`,
        element: <Partnership />,
      },
      {
        path: `${path.ISLANDER_MEMBER}`,
        element: <ReferralIslanderMember />,
      },
      {
        path: `${path.REFERRAL_TRANSACTIONS}`,
        element: <ReferralTransactions />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.VOUCHER_TRANSACTION_HISTORY,
        element: <VoucherTransactionHistory />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.VOUCHER_TRANSACTION_HISTORY}/:id`,
        element: <VoucherTransactionHistoryDetail />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.VOUCHER,
        element: <Voucher />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.VOUCHER}/${path.FEATURED_VOUCHER}`,
        element: <FeaturedVoucher />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.VOUCHER}/${path.VOUCHER_CATEGORIES}/${path.VOUCHER_CATEGORIES_MANAGE}`,
        element: <VoucherCategories />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.VOUCHER}/${path.VOUCHER_CATEGORIES}/${path.VOUCHER_CATEGORIES_ADD_NEW}`,
        element: <AddNew />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.VOUCHER}/${path.FEATURED_VOUCHER}/:id`,
        element: <FeaturedVoucher />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.AUDIT_LOGS,
        element: <AuditLogs />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: path.AUDIT_LOGS_MEMBERS,
        element: <AuditLogsMembers />,
      },
    ],
  },
  {
    path: path.AUTH,
    element: <AuthLayout />,
    children: [
      {
        path: path.SIGN_IN,
        element: <SignIn />,
      },
      {
        path: path.SIGN_UP,
        element: <SignUp />,
      },
      {
        path: path.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: path.CHANGE_PASSWORD,
        element: <ResetPassword isChangePassword />,
      },
      {
        path: path.RESET_PASSWORD_SUCCESS,
        element: <ResetPasswordSuccess />,
      },
      {
        path: path.CHANGE_PASSWORD_SUCCESS,
        element: <ChangePasswordSuccess />,
      },
      {
        path: path.FA_VERITICATION,
        element: <FAVerification />,
      },
      {
        path: path.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: path.PASSWORD_RESET_EXPIRED,
        element: <ResetPasswordExpired />,
      },
      {
        path: path[404],
        element: <Page404 />,
      },
      {
        path: path[500],
        element: <Page500 />,
      },
    ],
  },
  // detail
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.SDC_ADMINISTRATORS}/:id`,
        element: <SDCAdministratorsDetail />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.ISLAND_MEMBERS}/:id`,
        element: <IslanderMembersDetail />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.ISLAND_PARTNERS}/:id`,
        element: <IslandPartnerDetail />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {path: `${path.ALL_TRANSACTION}/:id`, element: <AllTransactionDetail />},
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.ALL_TRANSACTION}/:id?membershipId=:id`,
        element: <AllTransactionDetail />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.ALL_TRANSACTION}/:id?merchantId=:id`,
        element: <AllTransactionDetail />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.REFERRAL_PROGRAMME}/${path.PARTNERSHIP}/:id`,
        element: <PartnershipDetail />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [
      {
        path: `${path.REFERRAL_PROGRAMME}/${path.REFERRAL_TRANSACTIONS}/:id`,
        element: <ReferralTransactionDetail />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [{path: `${path.VOUCHER}/:id`, element: <VoucherDetail />}],
  },
  {
    path: path.DEFAULT,
    element: withAuth(<DashboardLayout />),
    children: [{path: `${path.ADD_NEW_VOUCHER}`, element: <VoucherDetail />}],
  },

  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
  {
    path: path.DEFAULT,
    element: <DashboardLayout />,
    children: [
      {
        path: `${path.CHANGE_LOG}`,
        element: <ChangeLog />,
      },
    ],
  },
];

export default routes;
