import styled from 'styled-components/macro';
import {spacing, display} from '@mui/system';
import {
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
  DialogTitle,
} from '@mui/material';

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const StyledIconClose = styled.div`
  position: absolute;
  right: 20px;
  top: 14px;
  cursor: pointer;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${props => props.theme.shadows[1]};
`;

const StyledModalTitle = styled(DialogTitle)`
  font-size: 20px;
  font-weight: 900;
`;
const Typography = styled(MuiTypography)(display);

const StyledDivBreadcrumbs = styled.div`
  margin-top: 10px;
`;

export {
  Card,
  Divider,
  Shadow,
  Typography,
  StyledDivBreadcrumbs,
  StyledModalTitle,
  StyledIconClose,
};
