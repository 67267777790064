/* eslint-disable node/no-extraneous-import */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {CardContent, FormControl, Grid, Checkbox} from '@mui/material';
import * as moment from 'moment';
import 'moment-timezone';

import {DataDetail, typeRoutes} from '../../../types/typeIslandersMembers';
import {path} from '../../../routes/path';
import {images} from '../../../constants/image';
import MembersService from '../../../services/Members/members.service';
import GuestsService from '../../../services/Guests/guests.service';
import success from '../../../constants/success';
import useAuth from '../../../hooks/useAuth';
import {formatDate, hasPermission} from '../../../utils/index';
import {AllModal} from './IslanderMemberDetail.method';
import {
  Breadcrumbs,
  Input,
  DatePicker,
  Stats,
  FooterDetail,
  MultipleSelectCheckbox,
} from '../../../components';
import queryString from 'query-string';

// style
import {
  Card,
  Divider,
  Shadow,
  Typography,
  StyledDivBreadcrumbs,
} from './IslanderMemberDetail.style';
import TagsService from '../../../services/Tags/tags.service';
import InterestsService from '../../../services/Interests/interests.service';
import SitecoreService from '../../../services/Sitecore/sitecore.service';
import SimpleSelectFilter from '../../../components/Selects/SimpleSelectFilter/SimpleSelectFilter';

function IslanderMembersDetail() {
  const location = useLocation();
  const paramsUrl = useParams();
  const idDetail = paramsUrl?.id;
  const {showNotification} = useAuth();
  const navigate = useNavigate();
  const canUpdate = hasPermission(path.ISLAND_MEMBERS, 'update');
  const canCreate = hasPermission(path.ISLAND_MEMBERS, 'create');
  const canDelete = hasPermission(path.ISLAND_MEMBERS, 'delete');

  // states
  const [valueDate, setValueDate] = useState(null);
  const [modal, setModal] = useState({
    isModalConfirm: false,
    isModalConfirmDelete: false,
    isModalSuccess: false,
    isModalSuccessDelete: false,
    isModalEditTier: false,
    isModalEditPoints: false,
  });
  const [initialValues, setInitialValues] = useState<DataDetail>({
    firstName: '',
    lastName: '',
    email: '',
    birthDate: '',
    mobileNumber: '',
    jubileeVerified: false,
    islandPartner: '',
    referralCode: '',
    codeUnique: '',
    country: '',
    termsAndConditionsOptIn: false,
  });
  const [tagIds, setTagIds] = useState([]);
  const [interestIds, setInterestIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState<any>({});
  const [disableArrays, setDisableArrays] = useState<string[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [watchlistMultiSelectCheckbox, setWatchlistMultiSelectCheckbox] =
    useState([]);
  const [optionWatchList, setOptionWatchList] = useState([]);

  const [interestMultiSelectCheckbox, setInterestMultiSelectCheckbox] =
    useState([]);
  const [optionInterest, setOptionInterest] = useState([]);

  const toggleModalConfirm = () => {
    setModal(preState => ({
      ...preState,
      isModalConfirm: !modal.isModalConfirm,
    }));
  };

  const toggleModalConfirmDelete = () => {
    setModal(preState => ({
      ...preState,
      isModalConfirmDelete: !modal.isModalConfirmDelete,
    }));
  };

  const toggleModalSuccess = () => {
    setIsLoading(true);
    new GuestsService().resetPassword(dataDetail.email).then(res => {
      if (res && res?.success) {
        setIsLoading(false);
        toggleModalConfirm();
        setModal(preState => ({
          ...preState,
          isModalSuccess: !modal.isModalSuccess,
        }));
      } else {
        setIsLoading(false);
      }
    });
  };

  const toggleModalSuccessDelete = () => {
    setIsLoading(true);
    new MembersService().softDelete(idDetail).then(res => {
      if (res && res?.success) {
        setIsLoading(false);
        toggleModalConfirmDelete();
        setModal(preState => ({
          ...preState,
          isModalSuccessDelete: !modal.isModalSuccessDelete,
        }));
        showNotification({
          message: success.DELETE_SUCCESS,
        });
      } else {
        setIsLoading(false);
        toggleModalConfirmDelete();
        showNotification({
          message: res.Message,
          variation: 'error',
        });
      }
    });
  };

  const onEditTier = () => {};

  const onEditPoints = () => {};

  const onEdit = values => {
    setIsLoading(true);
    new TagsService().assignToMembership({
      tagIds: tagIds,
      membershipId: idDetail,
    });
    new MembersService()
      .update(idDetail, {
        ...values,
        tagIds: tagIds,
        interestIds: interestIds,
        birthDate: formatDate(valueDate, 'MM/YYYY'),
        country: countries.find(item => item.label === initialValues?.country)
          ?.label,
      })
      .then(res => {
        if (res && res.success) {
          getIslanderMemberDetail();
          setIsLoading(false);
          showNotification({
            message: success.UPDATE_SUCCESS,
          });
        } else {
          setIsLoading(false);
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const getIslanderMemberDetail = () => {
    setIsLoading(true);
    getCountries();
    new MembersService().getOne(idDetail).then(res => {
      if (res && res.data && res.data.data) {
        const data = res?.data?.data;
        const tags = data.tags.reduce((items, item) => {
          items.push(item.id);
          return items;
        }, []);
        const interests = (data.interests || []).reduce((items, item) => {
          items.push(item.id);
          return items;
        }, []);
        setIsLoading(false);
        setInitialValues({
          ...initialValues,
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          mobileNumber: data?.mobileNumber,
          jubileeVerified: data?.jubileeVerified,
          referralCode: data?.codeUsed,
          codeUnique: data?.codeUnique,
          country: data?.country,
          termsAndConditionsOptIn: data?.termsAndConditionsOptIn,
        });
        setTagIds(tags);
        setInterestIds(interests);
        setDataDetail(data);
        setValueDate(
          moment.tz(data?.birthDate, 'MM/YYYY', 'America/Toronto').toISOString()
        );
        getWatchListTag(tags);
        getInterests(interests);
      }
    });
  };

  const onChangeMobileNumber = (e, setFieldValue) => {
    const {value} = e.target;
    const split = value.split('-') || [];
    let code = split[0].trim() || null;
    const phone =
      split[1] !== undefined ? split[1]?.replace(/[^\d+]/g, '')?.trim() : null;
    if (code && !code.startsWith('+')) code = `+${code}`;
    const formatValue =
      phone !== null
        ? phone
            ?.replace(/[^\d+]/g, '')
            ?.replace(/(.{4})/g, '$1 ')
            ?.trim()
        : '';
    setFieldValue(
      e.target.name,
      `${code !== null ? code : ''}${phone !== null ? '-' : ''}${
        formatValue || ''
      }`
    );
  };

  const onKeyPress = e => {
    const {value} = e.target;
    const split = value.split('-');
    const code = split[0] || '';
    const allowKey = (e.key >= 0 && e.key <= 9) || ['+', '-'].includes(e.key);
    const allowPlus = !value || e.key !== '+' || !value.includes('+');
    const allowMinus =
      e.key !== '-' ||
      !['', '+'].includes(code) ||
      (!['', '+'].includes(code) && !value.includes('-'));
    if (!(allowKey && allowPlus && allowMinus)) {
      e.preventDefault();
    }
  };

  const getWatchListTag = tags => {
    new TagsService()
      .getAll({_size: 2147483647, status_eq: 1, _sort: 'name:asc'})
      .then(res => {
        if (res?.data && Array.isArray(res.data.items)) {
          const newData = res?.data?.items?.reduce((items, item) => {
            items.push({
              title: item.name || '-',
              value: item.id,
            });
            return items;
          }, []);
          setOptionWatchList(newData);
          const selectedIds = newData?.filter(item => {
            return tags?.includes(item.value);
          });
          setWatchlistMultiSelectCheckbox(selectedIds);
        }
      });
  };

  const getInterests = interests => {
    new InterestsService().getAll({}).then(res => {
      if (res?.data && Array.isArray(res.data.items)) {
        const newData = res?.data?.items?.reduce((items, item) => {
          items.push({
            title: item.label || '-',
            value: item.id,
          });
          return items;
        }, []);
        setOptionInterest(newData);
        const selectedIds = newData?.filter(item => {
          return interests?.includes(item.value);
        });
        setInterestMultiSelectCheckbox(selectedIds);
      }
    });
  };

  const getCountries = async () => {
    await new SitecoreService().getCountry().then(res => {
      if (res?.status === 200) {
        const listCountry = res?.data?.data?.map((item, index) => ({
          value: index + 1,
          label: item.fields.countryName,
          image: null,
        }));

        setCountries(listCountry);
      }
    });
  };

  const handleClickReferralTransactions = dataDetail => {
    navigate(
      `/${path.REFERRAL_PROGRAMME}/${path.REFERRAL_TRANSACTIONS}?referralCodeUsed_eq=${dataDetail?.codeUnique}`,
      {
        state: {
          referralCodeUsed_eq: dataDetail?.codeUnique,
        },
      }
    );
  };

  const onChangeSelectCountry = (_, value) => {
    const newInitialValues = initialValues;
    newInitialValues.country = value.label;
    setInitialValues(newInitialValues);
  };

  useEffect(() => {
    if (dataDetail.numberOfReferrals === 0) {
      setDisableArrays([...disableArrays, 'referralTransactions']);
    } else {
      setDisableArrays(
        disableArrays.filter(item => !item.includes('referralTransactions'))
      );
    }
  }, [dataDetail]);

  useEffect(() => {
    getIslanderMemberDetail();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Edit Member Detail" />

      <AllModal
        isModalConfirm={modal.isModalConfirm}
        toggleModalConfirm={toggleModalConfirm}
        isModalConfirmDelete={modal.isModalConfirmDelete}
        isModalSuccessDelete={modal.isModalSuccessDelete}
        isModalSuccess={modal.isModalSuccess}
        isModalEditPoints={modal.isModalEditPoints}
        isModalEditTier={modal.isModalEditTier}
        toggleModalConfirmDelete={toggleModalConfirmDelete}
        toggleModalSuccessDelete={toggleModalSuccessDelete}
        toggleModalSuccess={toggleModalSuccess}
        setModal={setModal}
        onEditTier={onEditTier}
        onEditPoints={onEditPoints}
        isLoading={isLoading}
      />

      <Typography variant="h3" gutterBottom display="inline">
        Islander Member Detail
      </Typography>

      <StyledDivBreadcrumbs>
        <Breadcrumbs
          routes={[
            // typeRoutes('Dashboard', `/${path.DASHBOARD}`),
            typeRoutes(
              'Islander Members',
              `/${path.ISLAND_MEMBERS}?${queryString.stringify(location.state)}`
            ),
            typeRoutes('Detail', null, true),
          ]}
        />
      </StyledDivBreadcrumbs>
      <Divider my={6} />
      <Grid container spacing={6} mb={4}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title="Balance Points"
            helperText="The sum of all user's point values whose operation is add (i.e. operation = 1) minus all point values whose operation is deduct (i.e. operation = 2)"
            amount={dataDetail?.totalPoints}
            icon={images.icon_points}
            isManage={false}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title="Accumulated points"
            helperText="The sum of all user's point values with the operation of add (i.e. operation = 1)"
            amount={dataDetail?.totalTierPoints}
            icon={images.icon_transactional}
            isManage={false}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title="Number of Referrals"
            amount={dataDetail?.numberOfReferrals}
            icon={images.icon_transactional}
            isManage={false}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title="Current tier"
            amount={dataDetail?.tierDescription ?? dataDetail?.tierName}
            icon={images.icon_tier}
            isManage={false}
            date={dataDetail?.promotedDate}
            tierExpiryDate={dataDetail?.tierExpireDateTime}
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          ...initialValues,
          country: countries?.find(
            (item, index) => item.label === initialValues?.country
          ),
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          mobileNumber: Yup.string().required(),
        })}
        onSubmit={onEdit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          // isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container mb={9}>
              <Grid item xs={12} lg={12}>
                <Shadow>
                  <Card px={6} pt={6}>
                    <CardContent>
                      <Grid container spacing={5} mb="50px">
                        <Grid item xs={12}>
                          <Typography variant="subtitle2">
                            Account information
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <FormControl variant="standard" fullWidth>
                            <Input
                              label="First name"
                              type="text"
                              name="firstName"
                              value={values.firstName}
                              helperText={touched.firstName && errors.firstName}
                              onBlur={handleBlur}
                              disabled={!canUpdate}
                              onChange={handleChange}
                              error={Boolean(
                                touched.firstName && errors.firstName
                              )}
                              className="mandatory-field"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <FormControl variant="standard" fullWidth>
                            <Input
                              label="Last name"
                              type="text"
                              name="lastName"
                              value={values.lastName}
                              helperText={touched.lastName && errors.lastName}
                              onBlur={handleBlur}
                              disabled={!canUpdate}
                              onChange={handleChange}
                              error={Boolean(
                                touched.lastName && errors.lastName
                              )}
                              className="mandatory-field"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <FormControl
                            variant="standard"
                            fullWidth
                            sx={{marginTop: '10px'}}
                          >
                            <Input
                              type="email"
                              name="email"
                              label="Email"
                              readOnly
                              value={values.email}
                              disabled={!canUpdate}
                              helperText={touched.email && errors.email}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(touched.email && errors.email)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <FormControl fullWidth>
                            <Typography variant="inherit">
                              Place of residence
                            </Typography>
                            <SimpleSelectFilter
                              textFieldProps={{
                                placeholder: 'Select country',
                                size: 'small',
                                name: 'country',
                                variant: 'outlined',
                              }}
                              fullWidth
                              placeholder="Select country"
                              onChange={(e, newValue) => {
                                handleChange(e);
                                onChangeSelectCountry(e, newValue);
                              }}
                              disablePortal
                              onBlur={handleBlur}
                              options={countries}
                              disableClearable
                              value={
                                countries.find(
                                  item => item?.label === initialValues?.country
                                ) || null
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <FormControl variant="standard" fullWidth>
                            <DatePicker
                              label="Birthday"
                              mark="__/____"
                              format="MM/yyyy"
                              openTo="month"
                              views={['year', 'month']}
                              value={valueDate}
                              onChange={newValue => {
                                setValueDate(newValue);
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <FormControl variant="standard" fullWidth>
                            <Input
                              type="text"
                              inputMode="numeric"
                              onKeyPress={onKeyPress}
                              name="mobileNumber"
                              label="Phone number"
                              value={values.mobileNumber}
                              helperText={
                                touched.mobileNumber && errors.mobileNumber
                              }
                              onBlur={handleBlur}
                              onChange={e => {
                                onChangeMobileNumber(e, setFieldValue);
                              }}
                              error={Boolean(
                                touched.mobileNumber && errors.mobileNumber
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} display="flex">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="inherit">
                              Terms and Conditions Opt In Status
                            </Typography>
                            <Checkbox
                              name="termsAndConditionsOptIn"
                              checked={values.termsAndConditionsOptIn}
                              onChange={handleChange}
                              inputProps={{'aria-label': 'controlled'}}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} display="flex">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="inherit">
                              Verified as SC/PR
                            </Typography>
                            <Checkbox
                              name="jubileeVerified"
                              checked={values.jubileeVerified}
                              onChange={handleChange}
                              inputProps={{'aria-label': 'controlled'}}
                              disabled
                            />
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <FormControl variant="standard" fullWidth>
                            <Typography variant="inherit" mb={2} mt={3}>
                              Interests
                            </Typography>
                            <MultipleSelectCheckbox
                              placeholder="Select Interests"
                              value={interestMultiSelectCheckbox}
                              onChange={(e, values) => {
                                setInterestMultiSelectCheckbox([...values]);
                                setInterestIds(values.map(item => item.value));
                              }}
                              options={optionInterest}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <FormControl variant="standard" fullWidth>
                            <Typography variant="inherit" mb={2} mt={3}>
                              Watchlist Tags
                            </Typography>
                            <MultipleSelectCheckbox
                              placeholder="Select Watchlist tags"
                              value={watchlistMultiSelectCheckbox}
                              onChange={(e, values) => {
                                setWatchlistMultiSelectCheckbox([...values]);
                                setTagIds(values.map(item => item.value));
                              }}
                              options={optionWatchList}
                            />
                          </FormControl>
                        </Grid>
                        {values.codeUnique && (
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography variant="inherit" mb={2} mt={3}>
                              Unique Referral Code
                            </Typography>
                            <FormControl variant="standard">
                              <Input
                                readOnly
                                type="text"
                                name="codeUnique"
                                value={values.codeUnique}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {values.referralCode && (
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography variant="inherit" mb={2} mt={3}>
                              Referral Code Used
                            </Typography>
                            <FormControl variant="standard">
                              <Input
                                readOnly
                                type="text"
                                name="referralCode"
                                value={values.referralCode}
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Shadow>
              </Grid>
            </Grid>
            <FooterDetail
              isResetPassword
              isTransactions
              isVoucherTransaction
              isPartnership
              disableArrays={disableArrays || []}
              onClickReferralTransactions={() =>
                handleClickReferralTransactions(dataDetail)
              }
              isDelete={true}
              isEdit={idDetail ? true : false}
              toggleModalConfirm={toggleModalConfirm}
              toggleModalConfirmDelete={toggleModalConfirmDelete}
              titleDelete="Delete Account"
              canDelete={!canDelete}
              canUpdate={!canUpdate}
              canCreate={!canCreate}
              loading={isLoading}
              onClickTransactions={() => {
                navigate(`/${path.ALL_TRANSACTION}?membershipId=${idDetail}`, {
                  state: {
                    membershipId: dataDetail?.id,
                  },
                });
              }}
              onClickVoucherTransaction={() => {
                navigate(
                  `/${path.VOUCHER_TRANSACTION_HISTORY}?membershipEmail=${dataDetail?.email}`,
                  {
                    state: {
                      membershipEmail: dataDetail?.email,
                    },
                  }
                );
              }}
            />
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default IslanderMembersDetail;
