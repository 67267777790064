import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {FormHelperText} from '@mui/material';

interface SelectOptons {
  value?: string;
  label?: string;
  image?: string | null;
}

interface TextFieldProps {
  size?: string;
  placeholder?: string;
  name?: string;
}

interface SimpleSelectFilter {
  options?: Array<SelectOptons>;
  value?: SelectOptons;
  name?: string;
  fullWidth?: boolean | null;
  onChange?: (_: any, value: any) => void;
  onBlur?: () => void;
  textFieldProps?: TextFieldProps;
  helperText?: string | undefined;
  error?: boolean | null;
}

export default function SimpleSelectFilter({
  options,
  value,
  name,
  onChange,
  textFieldProps,
  helperText,
  error,
  ...rest
}: SimpleSelectFilter) {
  return (
    <>
      <Autocomplete
        options={options}
        getOptionLabel={option => option.label}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={textFieldProps?.placeholder}
            size={textFieldProps?.size}
            name={textFieldProps?.name}
            error={error}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option?.value}>
            {option.image !== null && (
              <img
                src={option.image}
                alt={option.label}
                style={{
                  width: `${option?.width}px`,
                  height: `${option?.height}px`,
                  marginRight: '10px',
                }}
              />
            )}
            {option.label}
          </li>
        )}
        onChange={onChange}
        value={value}
        {...rest}
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
}
