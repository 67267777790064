import BaseApiService from '../baseApi.service';

export default class SitecoreService extends BaseApiService {
  readonly basePath: string = 'sitecore/v1';
  constructor(props = null) {
    super(props);
  }

  getCountry = async () => {
    return await this.get('sitecore-country').then(res => res);
  };
}
