// icons
import icon_close from '../assets/icon/icon-close.svg';
import icon_points from '../assets/icon/icon-points.svg';
import icon_tier from '../assets/icon/icon-tier.svg';
import icon_transactional from '../assets/icon/icon-transactional.svg';
import icon_warning from '../assets/icon/icon-warning.svg';
import icon_success from '../assets/icon/icon-success.svg';
import icon_reset_password_success from '../assets/icon/icon-reset-password-success.svg';

import icon_dashboard from '../assets/icon/icon-dashboard.svg';
import icon_members from '../assets/icon/icon-members.svg';
import icon_partners from '../assets/icon/icon-partners.svg';
import icon_sdc_admin from '../assets/icon/icon-sdc-admin.svg';
import icon_transaction from '../assets/icon/icon-transaction.svg';
import icon_voucher from '../assets/icon/icon-voucher.svg';
import icon_audit_logs from '../assets/icon/icon-audit-logs.svg';
import icon_no_data from '../assets/icon/icon-no-data.svg';
import icon_voucher_transaction from '../assets/icon/icon-voucher-transaction.svg';
import icon_upload_new from '../assets/icon/icon-upload-new.svg';
import icon_media_library from '../assets/icon/icon-media-library.svg';
import icon_manage_assets from '../assets/icon/icon-manage-assets.svg';
import icon_upload from '../assets/icon/icon-upload.svg';
import icon_page from '../assets/icon/icon-page.svg';
import icon_upload_success from '../assets/icon/icon-upload-success.svg';
import icon_member_dashboard from '../assets/icon/member-dashboard.svg';
import icon_member_dashboard_create_new from '../assets/icon/create-new.svg';
import icon_manage_live from '../assets/icon/manage-live.svg';
import icon_trash from '../assets/icon/icon-trash.svg';
import icon_trash_error from '../assets/icon/icon-trash-error.svg';
import icon_page_error from '../assets/icon/icon-page-error.svg';
import icon_voucher_categories from '../assets/icon/icon-voucher-categories.svg';
import icon_add_new_categories from '../assets/icon/icon-add-new-categories.svg';
import icon_manage_categories from '../assets/icon/icon-manage-categories.svg';
import icon_featured_voucher from '../assets/icon/icon-featured-voucher.svg';
import icon_mobile_app_cms from '../assets/icon/icon-mobile-app-cms.svg';
import icon_terminated_members from '../assets/icon/icon-terminated-members.svg';

// images
import logo_sentosa from '../assets/images/logo_sentosa.png';
import logo_sidebar from '../assets/images/logo_sidebar.png';
import image_json_file from '../assets/images/image-json-file.svg';
import image_mp4_file from '../assets/images/image-mp4-file.svg';
import image_deactivate from '../assets/images/deactivate.svg';

const images = {
  icon_close,
  logo_sentosa,
  logo_sidebar,
  icon_points,
  icon_tier,
  icon_transactional,
  icon_warning,
  icon_success,
  icon_reset_password_success,
  icon_dashboard,
  icon_members,
  icon_partners,
  icon_sdc_admin,
  icon_transaction,
  icon_voucher,
  icon_audit_logs,
  icon_no_data,
  icon_voucher_transaction,
  icon_upload_new,
  icon_media_library,
  icon_manage_assets,
  image_mp4_file,
  image_json_file,
  icon_upload,
  icon_page,
  icon_upload_success,
  icon_member_dashboard,
  icon_manage_live,
  icon_trash,
  icon_trash_error,
  icon_page_error,
  icon_manage_categories,
  icon_add_new_categories,
  icon_voucher_categories,
  icon_featured_voucher,
  image_deactivate,
  icon_member_dashboard_create_new,
  icon_mobile_app_cms,
  icon_terminated_members,
};

export {images};
