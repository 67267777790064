/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
import {Helmet} from 'react-helmet-async';
import {
  Autocomplete,
  Box,
  CardContent,
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';

import {
  Breadcrumbs,
  Input,
  TextArea,
  RadioGroups,
  Select,
  Button,
} from '../../../components';

// style
import {
  Card,
  Divider,
  Shadow,
  Typography,
  StyledDivBreadcrumbs,
} from './MemberDashboardCreateNew.style';
import {typeRoutes} from '../../../types/typeAllTransaction';
import MemberDashBoardService from '../../../services/MemberDashboard/member-dashboard.service';
import MobileAssetsService from '../../../services/MobileAssets/mobile-assets.service';
import SimpleSelectFilter from '../../../components/Selects/SimpleSelectFilter/SimpleSelectFilter';

const radioOptions = [
  {
    key: 'Dark',
    value: 1,
  },
  {
    key: 'Light',
    value: 2,
  },
];

const radioOptionsCTALink = [
  {
    label: 'No destination',
    url: 'https://qa.sentosa.com.sg/1',
    value: 1,
  },
  {
    label: 'Map page',
    url: 'https://qa.sentosa.com.sg/2',
    value: 2,
  },
  {
    label: 'Shop Listing page',
    url: 'https://qa.sentosa.com.sg/3',
    value: 3,
  },
  {
    label: 'Member page',
    url: 'https://qa.sentosa.com.sg/4',
    value: 4,
  },
  {
    label: 'Islander Vouchers Listing page',
    url: 'https://qa.sentosa.com.sg/5',
    value: 5,
  },
  {
    label: 'Tips and guides Listing page',
    url: 'https://qa.sentosa.com.sg/6',
    value: 6,
  },
  {
    label: '500K Campaign page',
    url: 'https://qa.sentosa.com.sg/7',
    value: 7,
  },
  {
    label: 'MusicFest Campaign page',
    url: 'https://qa.sentosa.com.sg/8',
    value: 8,
  },
];

interface DataDetail {
  title?: string;
  description?: string;
  gradientColor?: number;
  mobileAssetId?: string;
  cTALink?: string;
  displayOrder?: number | undefined;
}

function MemberDashboardCreateNew() {
  const {showNotification} = useAuth();
  const [loading, setLoading] = useState(false);
  const [mastheadAssets, setMastheadAsset] = useState([]);
  const [initialValues, setInitialValues] = useState<DataDetail>({
    title: '',
    description: '',
    gradientColor: 1,
    mobileAssetId: '',
    cTALink: '',
  });

  const onSubmit = values => {
    if (isErrorForm()) return;
    setLoading(true);
    new MemberDashBoardService()
      .CreateNewMemberDashboardAsset({
        ...values,
        gradientColor: Number(initialValues?.gradientColor),
        cTALink: initialValues?.cTALink,
        mobileAssetId: initialValues?.mobileAssetId,
      })
      .then(res => {
        if (res !== null && res.success) {
          showNotification({
            message: 'Masthead Created successfully!',
          });
          setInitialValues({
            title: '',
            description: '',
            gradientColor: 1,
            mobileAssetId: '',
            cTALink: '',
          });
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
    setLoading(false);
  };

  const isErrorForm = () => {
    if (!initialValues?.mobileAssetId) {
      return true;
    }
  };

  const GetMastheadAssets = () => {
    new MobileAssetsService()
      .GetAllMobileAsset({_page: 0, _size: 99999, status_eq: 1})
      .then(res => {
        if (res.code === 200 && res?.data?.items?.length > 0) {
          const mastheadOptions = res?.data?.items?.map((masthead, index) => {
            return {
              label: masthead?.fileName,
              value: masthead?.id,
              image: masthead?.uRL,
              width: 60,
              height: 80,
            };
          });
          setMastheadAsset(mastheadOptions);
        }
      });
  };

  const onChangeSelectMasthead = value => {
    const newInitialValues = initialValues;
    newInitialValues.mobileAssetId = value.value;
    setInitialValues(newInitialValues);
  };

  useEffect(() => {
    GetMastheadAssets();
  }, []);

  return (
    <>
      <Helmet title="Member Dashboard" />

      <Box
        component="section"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h3" gutterBottom display="inline">
          Member Dashboard
        </Typography>

        <StyledDivBreadcrumbs>
          <Breadcrumbs
            routes={[
              typeRoutes(
                'Create Masthead Content for Logged In Users',
                undefined,
                true
              ),
            ]}
          />
        </StyledDivBreadcrumbs>

        <Divider my={6} />

        <Grid container mb="30px">
          <Grid item xs={12} lg={12}>
            <Shadow>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  title: Yup.string().required('Fill in the title'),
                  description: Yup.string().required('Fill the description'),
                  gradientColor: Yup.number().integer(),
                  cTALink: Yup.number().required('Select 1 App page'),
                })}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldTouched,
                  resetForm,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Card py={12} px={12}>
                      <CardContent sx={{padding: 0}}>
                        <Grid
                          container
                          rowSpacing={0}
                          columnSpacing={10}
                          mb="50px"
                        >
                          <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="subtitle2" mb={2}>
                                Title{' '}
                                <span className="mandatory-label">(*)</span>
                              </Typography>
                              <Input
                                placeholder="Enter title"
                                size="small"
                                name="title"
                                value={values.title}
                                variant="outlined"
                                helperText={touched.title && errors.title}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.title && errors.title)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="subtitle2" mb={2}>
                                Description{' '}
                                <span className="mandatory-label">(*)</span>
                              </Typography>
                              <TextArea
                                label=""
                                placeholder="Enter description"
                                maxRows={2}
                                name="description"
                                value={values.description}
                                helperText={
                                  touched.description && errors.description
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.description && errors.description
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl variant="standard" fullWidth>
                              <Typography variant="subtitle2" mb={2} mt={3}>
                                Background Gradient Colour
                              </Typography>
                              <RadioGroups
                                name="gradientColor"
                                options={radioOptions}
                                values={initialValues.gradientColor}
                                onChange={e => {
                                  handleChange(e);
                                  const newInitialValues = initialValues;
                                  newInitialValues.gradientColor =
                                    e.target.value;
                                  setInitialValues(newInitialValues);
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                              <Typography variant="subtitle2" mb={2}>
                                Masthead Asset{' '}
                                <span className="mandatory-label">(*)</span>{' '}
                                <Typography
                                  variant="body2"
                                  sx={{display: 'inline-block'}}
                                >
                                  [Select only 1 Asset from the list]
                                </Typography>
                              </Typography>
                              <SimpleSelectFilter
                                textFieldProps={{
                                  placeholder: 'Select masthead',
                                  size: 'small',
                                  name: 'mobileAssetId',
                                }}
                                fullWidth
                                onChange={(_, newValue) => {
                                  setFieldTouched('mobileAssetId', true);
                                  onChangeSelectMasthead(newValue);
                                }}
                                onBlur={handleBlur}
                                options={mastheadAssets}
                                disableClearable
                                helperText={
                                  touched.mobileAssetId && 'Select 1 masthead'
                                }
                                error={Boolean(
                                  touched.mobileAssetId &&
                                    !initialValues.mobileAssetId
                                )}
                                value={
                                  mastheadAssets.find(
                                    item =>
                                      item?.value ===
                                      initialValues?.mobileAssetId
                                  ) || null
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                              <Typography variant="subtitle2" mb={2}>
                                CTA Link{' '}
                                <span className="mandatory-label">(*)</span>{' '}
                                <Typography
                                  variant="body2"
                                  sx={{display: 'inline-block'}}
                                >
                                  [Select Redirection to 1 of the App page from
                                  Masthead]
                                </Typography>
                              </Typography>
                              <Select
                                labelId="cTALink"
                                name="cTALink"
                                size="small"
                                variant="outlined"
                                displayEmpty
                                placeholder="Select CTA link"
                                onChangeSelect={e => {
                                  handleChange(e);
                                  const newInitialValues = initialValues;
                                  newInitialValues.cTALink =
                                    radioOptionsCTALink.find(
                                      item => item?.value === e.target.value
                                    )?.url;
                                  setInitialValues(newInitialValues);
                                }}
                                onBlur={handleBlur}
                                helperText={touched.cTALink && errors.cTALink}
                                error={Boolean(
                                  touched.cTALink && errors.cTALink
                                )}
                                value={values.cTALink}
                                selectOptions={radioOptionsCTALink}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button type="submit" loading={loading}>
                          Create Masthead
                        </Button>
                      </Grid>
                    </Card>
                  </form>
                )}
              </Formik>
            </Shadow>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default MemberDashboardCreateNew;
