/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseApiService from '../baseApi.service';

export default class VoucherCategoryService extends BaseApiService {
  readonly basePath: string = 'ticketCategory/v1';
  constructor(props = null) {
    super(props);
  }

  exportCSV = async (
    params: object,
    onDownloadProgress: (progressEvent: any) => void
  ) => {
    return await this.getProgress(
      'exportCSV/voucher',
      params,
      {},
      progressEvent => onDownloadProgress(progressEvent)
    ).then(async res => res?.data);
  };

  getSirecoreCategory = async () => {
    return await this.get('sitecore-category').then(res => res);
  };
}
