/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {images} from '../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import {FormControl, Grid, CardContent, FormControlLabel} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {
  Modal,
  Input,
  TextArea,
  RadioGroups,
  Select,
  Button,
  Checkbox,
} from '../../../components';
import useAuth from '../../../hooks/useAuth';

// style
import {
  StyledIconClose,
  StyledModalTitle,
  Divider,
  Card,
  Typography,
} from './ModalUpdate.style';
// import SitecoreService from '../../../services/Sitecore/sitecore.service';
import VoucherCategoriesService from '../../../services/Voucher/voucher-category.service';

const radioOptions = [
  {
    key: 'AP’s Featured Voucher',
    value: 1,
  },
  {
    key: 'Vouchers to be tagged/already tagged with this category',
    value: 2,
  },
];

interface ModalUpdateProps {
  visible: boolean;
  onClose?: () => void;
  reloadPage?: () => void;
  idDetail?: string | number;
  icons?: any[];
  shopCategories?: any[];
}

function ModalUpdate({
  visible,
  onClose,
  reloadPage,
  idDetail,
  icons,
  shopCategories,
}: ModalUpdateProps) {
  const {showNotification} = useAuth();
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    title: '',
    description: '',
    iconActive: '',
    iconInactive: '',
    associatedSitecoreShopCategory: '',
    loyaltyVoucherDataSourceMobileGuestMode: 1,
    loyaltyVoucherDataSourceMobileLoggedIn: 1,
    showOnMobileDashboardGuestMode: false,
    showOnMobileDashboardLoggedIn: false,
    showOnMobileVoucherListing: false,
  });

  const onSubmit = values => {
    setLoading(true);
    new VoucherCategoriesService()
      .update(idDetail, {
        ...values,
        associatedSitecoreShopCategory: shopCategories.find(
          item => item?.value === initialValues.associatedSitecoreShopCategory
        )?.id,
        iconActive: icons.find(item => item?.value === values?.iconActive)
          ?.image,
        iconInactive: icons.find(item => item?.value === values?.iconInactive)
          ?.image,
      })
      .then(res => {
        if (res?.success) {
          showNotification({
            message: 'Updated successfully',
          });
          reloadPage();
          onClose();
          setLoading(false);
        } else {
          showNotification({
            message: 'Update failure',
            variation: 'error',
          });
          reloadPage();
          setLoading(false);
        }
      });
  };

  const getDataDetail = () => {
    new VoucherCategoriesService().getOne(idDetail).then(res => {
      if (res?.status === 200 && res?.data?.success) {
        setInitialValues({
          title: res?.data?.data?.title,
          description: res?.data?.data?.description,
          iconActive: icons.find(
            item => item?.image === res?.data?.data?.iconActive
          )?.value,
          iconInactive: icons.find(
            item => item?.image === res?.data?.data?.iconInactive
          )?.value,
          associatedSitecoreShopCategory: shopCategories.find(
            item => item?.id === res?.data?.data?.associatedSitecoreShopCategory
          )?.value,
          loyaltyVoucherDataSourceMobileGuestMode:
            res?.data?.data?.loyaltyVoucherDataSourceMobileGuestMode || 1,
          loyaltyVoucherDataSourceMobileLoggedIn:
            res?.data?.data?.loyaltyVoucherDataSourceMobileLoggedIn || 1,
          showOnMobileDashboardGuestMode:
            res?.data?.data?.showOnMobileDashboardGuestMode,
          showOnMobileDashboardLoggedIn:
            res?.data?.data?.showOnMobileDashboardLoggedIn,
          showOnMobileVoucherListing:
            res?.data?.data?.showOnMobileVoucherListing,
        });
      }
    });
  };

  useEffect(() => {
    if (idDetail) {
      getDataDetail();
    }
  }, [visible, idDetail]);

  return (
    <Modal visible={visible} onClose={onClose} maxWidth="lg">
      <>
        <StyledModalTitle>Update Category</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Voucher Title is required'),
              iconActive: Yup.number().required('Icon Active is required'),
              iconInactive: Yup.number().required('Icon InActive is required'),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Card>
                  <CardContent sx={{padding: 0}}>
                    <Grid container rowSpacing={0} columnSpacing={10} mb="50px">
                      <Grid item md={12}>
                        <FormControl fullWidth margin="normal">
                          <Typography variant="subtitle2" mb={2}>
                            Category Title
                            <span className="mandatory-label">(*)</span>
                          </Typography>
                          <Input
                            placeholder="Enter Title"
                            size="small"
                            name="title"
                            value={values.title}
                            variant="outlined"
                            helperText={touched.title && errors.title}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.title && errors.title)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={12}>
                        <FormControl fullWidth margin="normal">
                          <Typography variant="subtitle2" mb={2}>
                            Description
                          </Typography>
                          <TextArea
                            label=""
                            placeholder="Enter Description"
                            maxRows={2}
                            name="description"
                            value={values.description}
                            helperText={
                              touched.description && errors.description
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(
                              touched.description && errors.description
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={12}>
                        <FormControl fullWidth sx={{marginBottom: '20px'}}>
                          <Typography variant="subtitle2" mb={2}>
                            Icon asset – Inactive
                            <span className="mandatory-label">(*)</span>
                          </Typography>
                          <Select
                            labelId="iconInactive"
                            name="iconInactive"
                            size="small"
                            variant="outlined"
                            displayEmpty
                            placeholder="Select Image"
                            onChangeSelect={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              touched.iconInactive && errors.iconInactive
                            }
                            error={Boolean(
                              touched.iconInactive && errors.iconInactive
                            )}
                            value={values?.iconInactive}
                            selectOptions={icons}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={12}>
                        <FormControl fullWidth sx={{marginBottom: '20px'}}>
                          <Typography variant="subtitle2" mb={2}>
                            Icon asset – Active
                            <span className="mandatory-label">(*)</span>
                          </Typography>
                          <Select
                            labelId="iconActive"
                            name="iconActive"
                            size="small"
                            variant="outlined"
                            displayEmpty
                            placeholder="Select Image"
                            onChangeSelect={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.iconActive && errors.iconActive}
                            error={Boolean(
                              touched.iconActive && errors.iconActive
                            )}
                            value={values?.iconActive}
                            selectOptions={icons}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={12}>
                        <FormControl fullWidth sx={{marginBottom: '20px'}}>
                          <Typography variant="subtitle2" mb={2}>
                            Link to Sitecore’s Shop category
                          </Typography>
                          <Select
                            labelId="associatedSitecoreShopCategory"
                            name="associatedSitecoreShopCategory"
                            size="small"
                            variant="outlined"
                            displayEmpty
                            placeholder="Select category (if Sitecore category is attached, it will be shown as a category item)"
                            onChangeSelect={e => {
                              handleChange(e);
                              const newInitialValues = initialValues;
                              newInitialValues.associatedSitecoreShopCategory =
                                e.target.value;
                              setInitialValues(newInitialValues);
                            }}
                            onBlur={handleBlur}
                            helperText={
                              touched.associatedSitecoreShopCategory &&
                              errors.associatedSitecoreShopCategory
                            }
                            error={Boolean(
                              touched.associatedSitecoreShopCategory &&
                                errors.associatedSitecoreShopCategory
                            )}
                            value={values?.associatedSitecoreShopCategory}
                            selectOptions={shopCategories}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl variant="standard" fullWidth>
                          <Typography variant="subtitle2" mb={2}>
                            Display on?{' '}
                            <span className="mandatory-label">(*)</span>
                          </Typography>
                          <Grid container>
                            <Grid
                              item
                              sx={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                alignItems: 'center',
                              }}
                            >
                              <FormControlLabel
                                sx={{paddingLeft: '8px'}}
                                required
                                control={
                                  <Checkbox
                                    sx={{
                                      paddingLeft: 0,
                                    }}
                                    checked={
                                      values.showOnMobileDashboardLoggedIn
                                    }
                                    name="showOnMobileDashboardLoggedIn"
                                    value={values.showOnMobileDashboardLoggedIn}
                                    onChange={e => {
                                      handleChange(e);
                                      setInitialValues({
                                        ...values,
                                        showOnMobileDashboardLoggedIn:
                                          e.target.checked,
                                      });
                                    }}
                                    inputProps={{
                                      'aria-label': 'controlled',
                                    }}
                                  />
                                }
                                label="App Dashboard’s Discovery section (Logged In mode)"
                              />
                            </Grid>
                            <Grid
                              item
                              sx={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                alignItems: 'center',
                              }}
                            >
                              <FormControlLabel
                                sx={{paddingLeft: '8px'}}
                                required
                                control={
                                  <Checkbox
                                    sx={{
                                      paddingLeft: 0,
                                    }}
                                    checked={
                                      values.showOnMobileDashboardGuestMode
                                    }
                                    name="showOnMobileDashboardGuestMode"
                                    value={
                                      values.showOnMobileDashboardGuestMode
                                    }
                                    onChange={e => {
                                      handleChange(e);
                                      setInitialValues({
                                        ...values,
                                        showOnMobileDashboardGuestMode:
                                          e.target.checked,
                                      });
                                    }}
                                    inputProps={{
                                      'aria-label': 'controlled',
                                    }}
                                  />
                                }
                                label="App Dashboard’s Discovery section (Guest mode)"
                              />
                            </Grid>
                            <Grid
                              item
                              sx={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                alignItems: 'center',
                              }}
                            >
                              <FormControlLabel
                                required
                                control={
                                  <Checkbox
                                    sx={{
                                      paddingLeft: 0,
                                    }}
                                    checked={values.showOnMobileVoucherListing}
                                    name="showOnMobileVoucherListing"
                                    value={values.showOnMobileVoucherListing}
                                    onChange={e => {
                                      handleChange(e);
                                      setInitialValues({
                                        ...values,
                                        showOnMobileVoucherListing:
                                          e.target.checked,
                                      });
                                    }}
                                    inputProps={{
                                      'aria-label': 'controlled',
                                    }}
                                  />
                                }
                                label="App Voucher’s Listing filter"
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Grid>
                      <Grid item md={12}>
                        <FormControl variant="standard" fullWidth>
                          <Typography variant="subtitle2" mb={2} mt={3}>
                            For Guest Mode, data content is based on?{' '}
                            <Typography
                              variant="body2"
                              sx={{display: 'inline-block'}}
                            >
                              [Select one]
                            </Typography>{' '}
                            <span className="mandatory-label">(*)</span>
                          </Typography>
                          <RadioGroups
                            name="loyaltyVoucherDataSourceMobileGuestMode"
                            options={radioOptions}
                            values={
                              initialValues.loyaltyVoucherDataSourceMobileGuestMode
                            }
                            onChange={e => {
                              handleChange(e);
                              const newInitialValues = initialValues;
                              newInitialValues.loyaltyVoucherDataSourceMobileGuestMode =
                                e.target.value;
                              setInitialValues(newInitialValues);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={12}>
                        <FormControl variant="standard" fullWidth>
                          <Typography variant="subtitle2" mb={2} mt={3}>
                            For Logged In mode, data content is based on?{' '}
                            <Typography
                              variant="body2"
                              sx={{display: 'inline-block'}}
                            >
                              [Select one]
                            </Typography>{' '}
                            <span className="mandatory-label">(*)</span>
                          </Typography>
                          <RadioGroups
                            name="loyaltyVoucherDataSourceMobileLoggedIn"
                            options={radioOptions}
                            values={
                              initialValues.loyaltyVoucherDataSourceMobileLoggedIn
                            }
                            onChange={e => {
                              handleChange(e);
                              const newInitialValues = initialValues;
                              newInitialValues.loyaltyVoucherDataSourceMobileLoggedIn =
                                e.target.value;
                              setInitialValues(newInitialValues);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                      type="submit"
                      variant="contained"
                      loading={loading}
                      width="125px"
                    >
                      Save changes
                    </Button>
                  </Grid>
                </Card>
              </form>
            )}
          </Formik>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalUpdate;
